import React, { useState } from 'react';
import { connect } from 'react-redux';
import { panama } from '../../utils/handlers/handlers';

function ROIChartTooltip({city, data}) {

    const [unit] = useState(city.includes("panama") ? 'USD' : 'COP')

    return (
        <div className="h-14 w-auto">
            <h1 className="mx-2 mb-1">
                Año:
                <span className="ml-2 font-semibold">
                    {data.year}
                </span>
            </h1>
            <h1 className="mx-2 mb-1">
                Flujo de caja:
                <span style={{color: data.color}} className="ml-2 font-bold">
                    $ {data['cash flow']} {unit}
                </span>
            </h1>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      city: state.globalData.data.cotizacion.ciudad,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ROIChartTooltip);
