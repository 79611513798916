import React, { useEffect } from "react";

// Utils
import { content } from "../utils/handlers/handlers";

// Components
// import MapaErCo from "./PartialComponents/MapaErCo";
import PartnerCompany from "./PartnerCompany";
import OurPartner from "./OurPartner";

import { CO_PA_TX } from "../utils/handlers/handlers";

const Experience = ({ isMobile }) => {
  return (
 
    <section
      id="experience"
      className="w-screen lg:justify-center items-center text-font-greyLight mt-10"
    >
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 w-[90%] h-[70%] justify-center ">
        <div className="lg:col-span-3 mb-6 mx-auto ">
          {/* <MapaErCo width={isMobile ? "90%" : "150%"} /> */}
          <img src={CO_PA_TX} alt="Map" width={isMobile ? "90%" : "70%"} className=" pl-11 h-fit mx-auto" />
        </div>
        <div className="lg:col-span-3 lg:space-y-5 m-auto md:pr-60">
          {content.map((e) => (
            <div className="textMap flex flex-row items-center space-x-4 h-20" key={e.id}>
              <img className="h-16" src={e.icono} alt="icono" />
              <div className="h-16 border-r-4 mr-8 border-font-green"></div>
              <h2 key={e.id} className="text-lg lg:text-xl text-font-green">
                {e.textGreen} <br />
                <span className="text-font-greyLight lg:text-base">
                  {e.textGrey}
                </span>
              </h2>
            </div>
          ))}
        </div>
      </div>
      <div className="border-b-4 border-font-green justify-center items-center w-11/12 h-[2px] mt-10 mx-auto"></div>
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 py-5 my-5 h-auto justify-center items-center lg:divide-x-2 divide-gray-400 divide-y-2 mx-5 lg:divide-y-0">
        <div className="lg:col-span-3">
          <OurPartner />
        </div>
        <div className="lg:col-span-3 py-10 lg:py-0">
          <PartnerCompany />
        </div>
      </div>
    </section>
  );
};

export default Experience;
