import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { panama } from '../utils/handlers/handlers';

function WhatsappButton({ adviser, enableWhatsapp, city }) {

    const [countryCode] = useState(city.includes("panama") ? 507 : 57);
    
    return (
        <div className="fixed bottom-4 md:bottom-8 left-2 2xl:left-6 flex justify-between px-3 md:px-10">
            {
                enableWhatsapp ?
                <div className="toTopButton h-14 w-14 md:h-16 md:w-16 rounded-full shadow-2xl">
                    <a href={`https://wa.me/${countryCode}${adviser.wapp}`} className="w-full h-full flex justify-center items-center" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon
                            className="h-1/2 w-1/2"
                            icon={faWhatsapp}
                            color="white"
                            size="2x"
                        />
                    </a>
                </div> :
                <div></div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        adviser: state.adviserData.data,
        enableWhatsapp: state.globalData.data.whatsapp,
        city: state.globalData.data.cotizacion.ciudad,
    }
}

const mapDispatchToProps = () => {
    return {}
}
  

export default connect(mapStateToProps, mapDispatchToProps)(WhatsappButton);