import React from 'react';

// Partial Components
import SignatureManual from './SignatureManual';
import SignatureFile from './SignatureFile';

export default function Signature({ setSignature, signature }) {
    return (
        <div className="w-80 lg:w-full mx-auto overflow-y-auto h-80 flex flex-col justify-between my-10">
            <SignatureManual setSignature={setSignature} />
            <SignatureFile setSignature={setSignature} />
            {
                signature ? <img className="img-preview w-2/3 mx-auto my-10 rounded-lg" src={signature} alt="firma"/> : null
            }
        </div>
    )
}
