import React, { useState } from "react";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/pagination/pagination.min.css";

import {
  epmGray,
  eltesoroGray,
  bancolombiaGray,
  estraGray,
  eafitGray,
  comfamaGray,
  Avianca,
  CIJEANS,
  Ensa,
  Exxon,
  isagen,
} from "../utils/handlers/handlers";

const PartnerCompany = () => {

  const slides = [
    { image: epmGray, alt: "Socio" },
    { image: eltesoroGray, alt: "Socio" },
    { image: bancolombiaGray, alt: "Socio" },
    //{ image: estraGray, alt: "Socio" },
    //{ image: eafitGray, alt: "Socio" },
    //{ image: comfamaGray, alt: "Socio" },
    { image: Avianca, alt: "Socio" },
    //{ image: CIJEANS, alt: "Socio" },
    { image: Ensa, alt: "Socio" },
    //{ image: Exxon, alt: "Socio" },
    { image: isagen, alt: "Socio" },
  ];

  return (
    <section
      id="PartnerCompany"
      className="exp-erco panel flex flex-col  items-center font-bold text-3xl text-font-greyLight"
    >
      <div className=" text-2xl w-10/12">
        <Swiper
          slidesPerView={3}
          spaceBetween={15}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          }}
          pagination={{clickable: true}}
          modules={[Autoplay, Pagination]}
          className='swiper-container'
        >
        <div className="swiper-slide flex justify-center items-center">
          {
            slides.map((slide, index) => (
              <SwiperSlide 
                key={index}
                src={slide.image}
                alt={slide.alt}
              >
                  <img src={slide.image} alt={slide.alt}  />
              </SwiperSlide>)
            )
          }
        </div>
      </Swiper>
      <div className="swiper-custom-pagination swiper-container"/>
        </div>  
        <div>
          <h2 className="block mb-4 text-center text-font-green lg:text-2xl text-xl">
            Clientes
            <span className="text-font-greyLight">
              {" "}
              que confían en nosotros
            </span>
          </h2>
        </div>

    </section>
  );
};

export default PartnerCompany;
