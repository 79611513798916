import React from 'react'

export default function EnvironmentCard({ key, containerClass, valuesClass, image, name, value }) {
    
    const style = {
        backgroundColor: '#A2C52B'
    }

    return (
        <div key={key} className={"data-card " + containerClass} style={style}>
            <div className={valuesClass}>
                <h1 className="my-auto">
                    {value}
                </h1>
                <p className="mb-6 2xl:mb-5 w-22 lg:w-auto mx-auto lg:mx-0 text-base lg:text-2xl whitespace-nowrap">{name}</p>
            </div>
            <div className="bg-white rounded-full flex justify-center h-24 w-24 -top-36 mt-2 ml-2 lg:h-36 2xl:h-44 lg:w-36 2xl:w-44 relative lg:-top-40 2xl:-top-52 lg:mt-2 lg:ml-6 2xl:ml-8">
                <img alt={key + "image"} src={image} className="lg:w-2/3 w-3/5 lg:h-2/3 h-3/5 my-auto" />
            </div>
        </div>
    )
}