import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Utils
import { formatCurrency, formatNumber, icon, panama } from "../utils/handlers/handlers";
import { SETData } from "../utils/handlers/WebServices";
import { updateProjectTotalCost } from "../utils/redux";

// Partial Components
import Table from "./PartialComponents/Table";
import DataCard from "./PartialComponents/DataCard";

function CostoInversion({
  table_data,
  cash_data,
  city,
  isEpm,
  updateProjectTotalCost,
  cashflowInfo,
  allowedROI
}) {
  const columns = ["Descripción", "Cantidad", "Total"];
  const system_index = table_data.findIndex((data) =>
    data["Descripción"].includes("Sistema Solar")
  );
  const tableData = table_data.slice(system_index);

  const item = {
    icon,
  };

  const style = {
    kwPrice: {
      backgroundColor: "#4FAC2E",
      color: "white",
    },
    proyectCost: {
      backgroundColor: "#DCDC24",
      color: "white",
    },
    proyectCost2: {
      backgroundColor: "#92BD21",
      color: "white",
    },
    proyectCost3: {
      backgroundColor: "#737373",
      color: "white",
    },
  };

  const iconStyle = {
    fontSize: "1.2rem",
    position: "relative",
    top: "-8rem",
  };

  const validatePan = city.includes("panama");

  const cop_format = Intl.NumberFormat(validatePan ? "en-US" : "es-CO", {
    style: "currency",
    currency: validatePan ? "USD" : "COP",
    maximumSignificantDigits: validatePan ? 3 : 12,
  });

  const value =  !validatePan ? formatCurrency(Math.round(cash_data["Precio por kWp"])): `$ ${Number(cash_data["Precio por kWp"]).toFixed(4)}`;
  const name = "Precio por Wp";

  const [sumSubtotal, setSumSubtotal] = useState(0);
  const [totalIVA, setTotalIVA] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [nameIva, setNameIva] = useState(
    city.includes("panama") ? "ITBMS" : "IVA"
    );
  const [taxBenefitsTotal, setTaxBenefitsTotal] = useState(0);
  const [taxBenefitsRatio, setTaxBenefitsRatio] = useState(0);
  const [viewTax, setViewTax] = useState(false);

  const [tableInvestment, setTableInvestment] = useState(tableData);
  const [benefits, setBenefits] = useState(false)

  setTimeout(()=>{
    setBenefits(true)
  },2000)

  const getUpdatedValues = async () => {
    const regex = /[0-9]+(\.[0-9]+)?/;
    const systemPower = tableData[0]['Descripción'].match(regex)[0];
    const roundedSystemPower = parseFloat(systemPower).toFixed(2);
    tableData[0]['Descripción'] = tableData[0]['Descripción'].replace(regex, roundedSystemPower);

    if (isEpm && tableData[0] && tableData[0]["Precio Unitario"] === "$ 0") {
      tableData[0].Total = cop_format.format(cash_data.subtotal);
      tableData[0]["Precio Unitario"] = cop_format.format(cash_data.subtotal);
    }

    setTableInvestment(tableData);

    const requestObj = {
      items: tableData,
      iva: cash_data.iva,
    };

    const { data } = await SETData(requestObj, "get_project_total_cost");
    setSumSubtotal(Math.round(data.subtotal));
    setTotalIVA(Math.round(data.IVA));
    setTotalCost(Math.round(data.totalCost));
    updateProjectTotalCost(data);
  };

  useEffect(() => {
    getUpdatedValues();
  }, []);

  useEffect(() => {
    const ivaRecovery = cashflowInfo.reduce((acumulador, subarreglo) => (acumulador + subarreglo[10]), 0) * 1000;
    const taxBenefits = cashflowInfo.reduce((acumulador, subarreglo) => (acumulador + subarreglo[11]), 0) * 1000;
    const depreciation = cashflowInfo.reduce((acumulador, subarreglo) => (acumulador + subarreglo[12]), 0) * 1000;

    const total = taxBenefits + ivaRecovery + depreciation;
    const ratio = ((total / totalCost) * 100).toFixed(2);
  
    setTaxBenefitsTotal(cop_format.format(Math.round(total)))

    setTaxBenefitsRatio(ratio)

    if (allowedROI && total && ratio) setViewTax(true);
    else setViewTax(false);
  }, [cashflowInfo, totalCost, cop_format, allowedROI, benefits]);

  return (
    <section
      id="investment"
      className="investment w-screen h-screen justify-center flex flex-col pt-52 lg:pt-20"
    >
      <div className="flex flex-col">
        <div className="w-11/12 2xl:max-h-80 md:max-h-64 mx-auto px-2 overflow-x-scroll md:overflow-x-hidden">
          <Table columns={columns} data={tableInvestment} />

          {/* CASH DATA */}
          <div className="w-full grid grid-rows-2 grid-cols-2 md:grid-cols-4 mt-5">
            <h2 className="h-10 md:col-start-3 text-center">Subtotal</h2>
            <h2 className="h-10 text-center">
              {/* {cop_format.format(sumSubtotal)} */}
              {formatCurrency(sumSubtotal)}
            </h2>
            <h2 className="h-10 md:col-start-3 text-center">{nameIva}</h2>
            <h2 className="h-10 text-center">{formatCurrency(totalIVA)}</h2>
          </div>
        </div>

        <div className="w-full flex flex-col-reverse md:flex-row justify-between my-2 lg:my-6">
          {/* DATA CARD */}
          <div className="w-11/12 flex flex-wrap md:flex-row px-2 mx-auto md:my-auto md:overflow-hidden gap-2">
            <DataCard
              item={item}
              style={style.kwPrice}
              containerClass="w-full md:w-2/5 h-28 md:ml-20 rounded-lg overflow-hidden"
              valuesClass="text-center h-full flex flex-col justify-around"
              value={value}
              name={name}
              iconStyle={iconStyle}
              iconSize="7x"
            />
            <DataCard
              item={item}
              style={style.proyectCost}
              containerClass="w-full md:w-2/5 h-28 mt-5 md:mt-0 md:ml-20 rounded-lg overflow-hidden"
              valuesClass="text-center h-full flex flex-col justify-around"
              value={formatCurrency(totalCost)}
              name={`Total Proyecto ${nameIva} Incluido`}
              iconStyle={iconStyle}
              iconSize="7x"
            />
            {viewTax && (
              <>
                {/* CARD'S ROI */}
                <DataCard
                  item={item}
                  style={style.proyectCost2}
                  containerClass="w-full md:w-2/5 h-28 mt-5 md:mt-0 md:ml-20 rounded-lg overflow-hidden"
                  valuesClass="text-center h-full flex flex-col justify-around"
                  value={formatCurrency(taxBenefitsTotal)}
                  name={`Beneficios tributarios`}
                  iconStyle={iconStyle}
                  iconSize="7x"
                />
                <DataCard
                  item={item}
                  style={style.proyectCost3}
                  containerClass="w-full md:w-2/5 h-28 mt-5 md:mt-0 md:ml-20 rounded-lg overflow-hidden"
                  valuesClass="text-center h-full flex flex-col justify-around"
                  value={`${formatNumber(taxBenefitsRatio)}%`}
                  name={`Beneficios tributarios / Total`}
                  iconStyle={iconStyle}
                  iconSize="7x"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    table_data: state.globalData.data["cotizacion estandar"],
    cash_data: state.globalData.data["costo de inversion"],
    city: state.globalData.data.cotizacion.ciudad,
    isEpm: state.globalData.data.cotizacion.isEpm,
    cashflowInfo: state.chartsData.data.cashflow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProjectTotalCost: (data) => dispatch(updateProjectTotalCost(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostoInversion);
