import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Utils
import { getFile } from '../../utils/redux';
import { panelIcon, infoIcon, tooltipText, selectDocStyles } from '../../utils/handlers/handlers';
import { addNewTag } from '../../utils/handlers/zohoIntegration';

// Styled Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Select from 'react-select';
import Loader from "react-loader-spinner";
import Tooltip from './Tooltip';

function PanelCard({ getFile, pdfLoaded, panels, docOptions, _idZoho, areaPanel }) {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(pdfLoaded) {
            setIsLoading(false);
        }
    }, [pdfLoaded]);

    const getTechnicalDocument = (option) => {
        const folder = 'fichas_tecnicas';
        setIsLoading(true);
        getFile(`${folder}/${option}.pdf`);
        addNewTag(_idZoho, 'ErQte Descargó Doc Tecn')
    }


    return (
        <div className="w-5/6 mx-auto lg:w-full h-64 2xl:h-full">
            <div className="panel-card-container w-full h-full rounded-lg"></div>
            <div className="panel-card w-full h-64 rounded-lg relative -top-64 2xl:-top-72 2xl:h-full"></div>
            <div className="panel-card-info w-full h-64 2xl:h-full rounded-lg flex flex-col justify-between">

                {/* Info Icon and Tooltip */}
                <div className="h-3/12 flex justify-end mt-2 mr-2">
                    <div
                        style={{
                            fontSize: '0.5rem',
                            cursor: 'pointer'
                        }}
                        data-tip={tooltipText}
                        data-event='click focus'
                        data-for='test'
                    >
                        <FontAwesomeIcon
                            icon={infoIcon}
                            color="white"
                            size="3x"
                        />
                    </div>
                    <ReactTooltip
                        id='test'
                        globalEventOff='click'
                        place="left"
                        multiline={true}
                    />
                </div>
                
                {/* Panels Info */}
                <div className="h-6/12 flex mx-auto">
                    <div className="my-auto mx-4">
                        <FontAwesomeIcon
                            icon={panelIcon}
                            color="white"
                            size="3x"
                        />
                    </div>
                    <div className="mx-auto text-center">
                        <h1>{panels}</h1>
                        <h4>Paneles</h4>
                    </div>
                </div>

                { areaPanel && 
                <div className="mx-auto text-center">
                    Area efectiva: {Math.ceil(areaPanel)} m2
                </div>
                }
                
                {/* See Doc Button */}
                <div className="h-1/6 w-full mb-4">
                    <div className="w-4/5 lg:w-3/5 h-full mx-auto flex justify-center">
                        {
                            isLoading ?
                            <Loader
                                className="w-11 mx-auto mt-2"
                                type="TailSpin"
                                color="#fff"
                                width={35}
                                height={35}
                            /> :
                            <Select
                                className="h-full w-full rounded-3xl"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                name="versions"
                                options={docOptions.length > 0 ? docOptions : []}
                                onChange={option => getTechnicalDocument(option.value)}
                                placeholder='Ver documentación'
                                styles={selectDocStyles}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    const source = state.filesData;
    return {
        pdfLoaded: source.data,
        docOptions: state.globalData.data["fichas tecnicas"],
        _idZoho: state.globalData.data.cotizacion._idZoho
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFile: (folder, fileName) => dispatch(getFile(folder, fileName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelCard);