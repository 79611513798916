import {
  CREATE_VARIABLE_DATA_TYPE,
  UPDATE_VARIABLE_DATA_TYPE
} from "./variablesDataTypes";

export const createVariableData = (data) => {
  return {
    type: CREATE_VARIABLE_DATA_TYPE,
    payload: data
  };
};

export const updateVariableDataSuccess = (data) => {
  return {
    type: UPDATE_VARIABLE_DATA_TYPE,
    payload: data
  };
};

export const updateVariableData = (data) => {
  return (dispatch) => {
    dispatch(updateVariableDataSuccess(data));
  };
};
