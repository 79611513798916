import React from "react";
import { socio, socio2 } from "../utils/handlers/handlers";

const ourPartner = () => {
  return (
    <section
      id="ourPartner"
      className="exp-erco panel flex justify-center items-center font-bold"
    >
      <div className="grid grid-cols-3 gap-3 items-center text-xl lg:space-x-4 lg:text-2xl">
        <div className="col-span-3">
          <img className="my-2 h-10 lg:h-16" src={socio2} alt="Socio" />
        </div>
        <div className="col-span-3">
          <img className="my-2 h-10 lg:h-16" src={socio} alt="Socio" />
        </div>
        <div className="col-span-6">
          <h2 className="block mb-4 text-font-greyLight text-center">
            Nuestros
            <span className="text-font-green"> Socios</span>
          </h2>
        </div>
        {/* <div className="w-full lg:w-0 lg:h-28 border-b-4 lg:border-r-4 lg:mr-8 border-font-green"></div> */}
      </div>
    </section>
  );
};

export default ourPartner;
