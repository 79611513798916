import React from "react";

import { View, Image } from "@react-pdf/renderer";

// utils
import { exPdf1 } from "../../../utils/handlers/handlers";
import { styles } from "../../../utils/handlers/stylesPDF";

const ExperiencePdf = () => {
  return (
    <View style={styles.containerExp}>
        <Image src={exPdf1} alt="imagen" style={styles.ExpImg1} />
    </View>
  );
};

export default ExperiencePdf;
