import React from "react";
import { connect } from "react-redux";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { PDFDownloadLink } from "@react-pdf/renderer";

import FinancialAnalysisPdf from "../Doc/FinancialAnalysisPdf";

const ButtonToPrint = ({ cashflowInfo, year, price, discount, excess, activeLeasing, vpnSolarSystem, vpnTraditionalEnergy, vpnProject, isEpm }) => {
  return (
    <div className="fixed bottom-4 right-8 top-3 flex justify-between px-3 ">
      <PDFDownloadLink
        document={
          <FinancialAnalysisPdf
            data={cashflowInfo}
            year={year}
            price={price}
            discount={discount}
            excess={excess}
            activeLeasing={activeLeasing}
            vpnSolarSystem={vpnSolarSystem}
            vpnTraditionalEnergy={vpnTraditionalEnergy}
            vpnProject={vpnProject}
            isEpm={isEpm}
          />
        }
      >
        <FontAwesomeIcon icon={faDownload} color="#7c7c7c" size="1x" />
      </PDFDownloadLink>
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
      leasing: state.typeOperation.leasing
    };
};
  
export default connect(mapStateToProps, {})(ButtonToPrint);
