import {
  FETCH_GLOBAL_DATA_SUCCESS,
  FETCH_GLOBAL_DATA_FAILURE,
  UPDATE_PROJECT_TOTAL_COST
} from "./globalDataTypes";
import { getQuoteVersion, getClientId } from "../../handlers/handlers";
import { GETData } from "../../handlers/WebServices";

/* Global Data */
export const fetchGlobalDataSuccess = (data) => {
  return {
    type: FETCH_GLOBAL_DATA_SUCCESS,
    payload: data
  };
};

export const fetchGlobalDataFailure = (error) => {
  return {
    type: FETCH_GLOBAL_DATA_FAILURE,
    payload: error
  };
};
export const fetchGlobalData = () => {
  const version = getQuoteVersion();
  const id = getClientId();

  return (dispatch) => {
    GETData(`${id}/${version}`)
      .then((response) => {
        const data = response.data;
        dispatch(fetchGlobalDataSuccess(data));
      })
      .catch((error) => {
        console.log("ERROR");
        dispatch(fetchGlobalDataFailure(error.message));
      });
  };
};

/* Update project total cost */
export const updateProjectTotalCostSuccess = (data) => {
  return {
    type: UPDATE_PROJECT_TOTAL_COST,
    payload: data
  };
};

export const updateProjectTotalCost = (data) => {
  return (dispatch) => {
    dispatch(updateProjectTotalCostSuccess(data));
  };
};
