import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Styled Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//Modals
import TermsAndConditions from "./PartialComponents/TermsAndConditions";
import ContractModal from "./PartialComponents/ContractModal";

// Utils
import { createContractPreview } from '../utils/redux';
import { getContract } from '../utils/redux';
import { addNewTag } from '../utils/handlers/zohoIntegration';

function Firmar({ isExpired, firmar, contractKey, contract, getContract, createContractPreview, client, power, total, city, signDate, quoteType, guarantee, prequote, _idZoho }) {

  const [reviewdTerms, setReviewdTerms] = useState(false);
  const [generateContract, setGenerateContract] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openContract, setopenContract] = useState(false);

  useEffect(() => {
    if (contract.loaded) {
      setOpenModal(true);
      setIsLoading(false);
    }
  }, [contract]);

  const getSignedContract = () => {
    getContract(contractKey);
    setIsLoading(true);
    setopenContract(true);
    addNewTag(_idZoho, 'ErQte Clic Revisa tu Cot');
  }
  
  useEffect(() => {
    if (contract.signed && openContract) {
      setIsLoading(false);
      window.open(contract.url);
    }
  }, [contract, openContract]);

  const handleApprove = (expiredContract=false) => {
    if(expiredContract) {
      setOpenTerms(true);
      return;
    }

    if(reviewdTerms) {
      createContractPreview(null, client, power, total, city, signDate, quoteType);
      setIsLoading(true);
      addNewTag(_idZoho, 'ErQte Clic Revisar PreC');
      setOpenTerms(false);
    } else {
      setOpenTerms(true);
      setGenerateContract(true);
    }
  }
  
  const handleModalClose = () => {
    setOpenTerms(false);
    setOpenModal(false);
    if(openTerms && generateContract) {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if(generateContract) {
      handleApprove();
    }
  }, [reviewdTerms]);

  if (isExpired) {
    return (
      <section id="firmar" className="firmar sign flex items-center justify-center w-screen h-screen">
        <div className="h-auto mt-24 lg:mt-40 2xl:mt-60">
          <div className="w-5/6 mx-auto flex flex-col">
            <div className="w-auto mx-auto">
              <FontAwesomeIcon icon={faSignature} color="#737373" size="5x" />
            </div>
            <div className="lg:w-1/3 h-24 mx-auto my-16">
              <Button onClick={() => handleApprove(true)} bsPrefix="approve sign-button px-2 lg:px-0">
                Revisar Precontrato
              </Button>
            </div>
            <div className="lg:w-2/3 mx-auto lg:mb-0">
              {
                isExpired ?
                <p className="text-justify">
                  Esta Propuesta Económica ha vencido. Puedes revisar otras versiones,
                  o ponerte en contacto con tu asesor para realizar una actualización de tus datos.
                </p> :
                <p className="text-justify">
                  Registrando su firma acepta en su totalidad los
                  <span className="mx-1">términos y condiciones</span>
                  de este documento, así como el uso de la firma electrónica como
                  una representación de una firma en documento físico con pluma y
                  papel.
                </p>
              }
            </div>
            <TermsAndConditions
              openModal={openTerms}
              handleClose={handleModalClose}
              quoteType={quoteType}
              guarantee={guarantee}
              setReviewdTerms={setReviewdTerms}
              city={city}
            />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section id="firmar" className="firmar sign flex items-center justify-center w-screen h-screen">
        <div className="h-auto mt-24 lg:mt-40 2xl:mt-60">
          <div className="w-5/6 mx-auto flex flex-col">
            <div className="w-auto mx-auto">
              <FontAwesomeIcon icon={faSignature} color="#737373" size="5x" />
            </div>

            {firmar ? (
              <>
                <div className="lg:w-1/3 h-24 mx-auto my-16">
                  {isLoading ? (
                    <Loader
                      className="w-11 mx-auto mt-2"
                      type="TailSpin"
                      color="#92BD21"
                      width={60}
                      height={60}
                    />
                  ) : (
                    <Button
                      onClick={() => getSignedContract()}
                      bsPrefix="approve sign-button px-3 lg:px-0"
                    >
                      Revisa tu contrato
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="lg:w-1/3 h-24 mx-auto my-16">
                  {isLoading ? (
                    <Loader
                      className="w-11 mx-auto mt-2"
                      type="TailSpin"
                      color="#92BD21"
                      width={60}
                      height={60}
                    />
                  ) : (
                    <Button
                      onClick={() => handleApprove()}
                      bsPrefix="approve sign-button px-2 lg:px-0"
                    >
                      Revisar Precontrato
                    </Button>
                  )}
                </div>
                <div className="lg:w-2/3 mx-auto lg:mb-0">
                  <p className="text-justify">
                    Registrando su firma acepta en su totalidad los
                    <span className="mx-1" onClick={() => setOpenTerms(true)}>
                      términos y condiciones
                    </span>
                    de este documento, así como el uso de la firma electrónica
                    como una representación de una firma en documento físico con
                    pluma y papel.
                  </p>
                </div>
                <TermsAndConditions
                  openModal={openTerms}
                  handleClose={handleModalClose}
                  quoteType={quoteType}
                  guarantee={guarantee}
                  setReviewdTerms={setReviewdTerms}
                  generateContract={generateContract}
                  city={city}
                />
                <ContractModal
                  openModal={openModal}
                  handleClose={handleModalClose}
                  prequote={prequote}
                />
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const source = state.globalData.data;
  return {
    firmar: source.firmar,
    contractKey: source.key,
    contract: state.contractData.data,
    client: source.cliente,
    total: source["costo de inversion"].total,
    city: source.cotizacion.ciudad,
    power: source["retorno de inversion"].chartData["chart data"]["calculation data"].potencia_KWP,
    signDate: source.cotizacion.fecha_expiracion,
    quoteType: source.cotizacion.tipo,
    guarantee: source.cotizacion.garantia,
    prequote: source.cotizacion.precotizacion,
    _idZoho: state.globalData.data.cotizacion._idZoho
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getContract: (key) => dispatch(getContract(key)),
    createContractPreview: (signature, client, power, total, city, signDate, quoteType) => dispatch(createContractPreview(signature, client, power, total, city, signDate, quoteType))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Firmar);
