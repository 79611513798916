import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Utils
import { signContract, cleanContractInfo, getContract } from '../../utils/redux';
import { addNewTag } from '../../utils/handlers/zohoIntegration';

// Styled Components
import Button from 'react-bootstrap/Button';
import { ModalContent, ModalFooter, StaticDialog } from 'react-st-modal';
import Loader from "react-loader-spinner";

// Partial Components
import ContractPreview from './ContractPreview';
import Signature from './Signature';
import MessageModal from './MessageModal';

function ContractModal({ signContract, openModal, handleClose, client, power, total, city, signDate, quoteType, contract, cleanContractInfo, getContract, _idZoho, prequote }) {

    const [preview, setPreview] = useState(true);
    const [signature, setSignature] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openMessageModal, setOpenMessageModal] = useState(false);
    const [contractKey, setContractKey] = useState("")

    const generateDocument = () => {
        setIsLoading(true);
        signContract(signature, client, power, total, city, signDate, quoteType);
        addNewTag(_idZoho, 'ErQte Clic Generar DocC');
    }

    useEffect(() => {
        if(contract.signed) {
            setOpenMessageModal(true);
            setIsLoading(false);
            handleClose();
        } else if(contract.url.includes('.pdf')) {
            setContractKey(contract.url)
        }
    }, [contract, handleClose])

    const closeModal = () => {
        setPreview(true);
        handleClose();
    }

    const toSignature = () => {
        addNewTag(_idZoho, 'ErQte Clic Firmar Contr');
        setPreview(false)
        cleanContractInfo();
    }

    const validateSignContractOption = () => {
        if(!prequote) {
            preview ? toSignature() : generateDocument()  
        } 
    }

    return (
        <StaticDialog
            className="signature-modal"
            isOpen={openModal}
            isCanClose={false}
            showCloseIcon={true}
            onAfterClose={() => closeModal()}
            title={preview ? "Precontrato" : "Firma de contrato" }
        >
            <ModalContent>
                {
                    preview ?
                    <ContractPreview contract={contract} /> :
                    <Signature setSignature={setSignature} signature={signature} />
                }
            </ModalContent>
            <ModalFooter>
                <div className="w-full lg:w-1/2 h-14 mx-auto relative bottom-0">
                    {
                        isLoading ? 
                        <Loader
                            className="w-11 mx-auto mt-2"
                            type="TailSpin"
                            color="#92BD21"
                            width={45}
                            height={45}
                        /> : 
                        <Button
                            bsPrefix={`approve signature ${prequote ? 'disabled' : ''}`}
                            onClick={() => validateSignContractOption()}
                        >
                            {
                                preview ? 
                                'Firmar Contrato' :
                                'Generar Documento'
                            }
                        </Button>
                    }
                </div>
            </ModalFooter>
            <MessageModal
                getContract={getContract}
                contractKey={contractKey}
                openMessageModal={openMessageModal}
                setOpenMessageModal={setOpenMessageModal}
                contract={contract}
                _idZoho={_idZoho}
            />
        </StaticDialog>
    );
}


const mapStateToProps = state => {
    const source = state.globalData.data;
    return {
        client: source.cliente,
        total: source["costo de inversion"].total,
        city: source.cotizacion.ciudad,
        power: source["retorno de inversion"].chartData["chart data"]["calculation data"].potencia_KWP,
        signDate: source.cotizacion.fecha_expiracion,
        quoteType: source.cotizacion.tipo,
        contract: state.contractData.data,
        id: state.globalData.data._idCliente,
        _idZoho: state.globalData.data.cotizacion._idZoho
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signContract: (signature, client, power, total, city, signDate, quoteType) => dispatch(signContract(signature, client, power, total, city, signDate, quoteType)),
        getContract: (key) => dispatch(getContract(key)),
        cleanContractInfo: () => dispatch(cleanContractInfo())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractModal);