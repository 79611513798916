import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Partial Components
import FinancialAnalysisTable from "./FinancialAnalysisTable";
import LeasingAnalysisTable from "./LeasingAnalysisTable";
import ButtonToPrint from "./ButtonToPrint";

// Styled Components
import { ModalContent, StaticDialog } from "react-st-modal";
import { calculateLeasingVPN } from "../../utils/handlers/handlers";

function FinancialAnalysis({
  cashflowInfo,
  openModal,
  handleClose,
  year,
  price,
  discount,
  excess,
  activeLeasing,
  isEpm,
  city
}) {
  const [vpnSolarSystem, setVpnSolarSystem] = useState(0)
  const [vpnTraditionalEnergy, setVpnTraditionalEnergy] = useState(0)
  const [vpnProject, setVpnProject] = useState(0)
  
  const validatePan = "panama".includes(city)
 

  const cop_format = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumSignificantDigits: 9
  });

  useEffect(() => {
    const dataSolarSystem = cashflowInfo.map((el) => el[14])
    const dataTraditionalEnergy = cashflowInfo.map((el) => el[17])
    const dataProject = cashflowInfo.map((el) => el[18])

    const tempDataSolarSystem = Math.round(calculateLeasingVPN(dataSolarSystem, discount, year))
    const tempDataTraditionalEnergy = Math.round(calculateLeasingVPN(dataTraditionalEnergy, discount, year))
    const tempDataProject = Math.round(calculateLeasingVPN(dataProject, discount, year))

    setVpnSolarSystem(cop_format.format(tempDataSolarSystem))
    setVpnTraditionalEnergy(cop_format.format(tempDataTraditionalEnergy))
    setVpnProject(cop_format.format(tempDataProject))
  }, [discount, year, cashflowInfo, cop_format])

  // console.log("Cashflow", cashflowInfo)

  return (
    <StaticDialog
      className="cashflow-modal"
      isOpen={openModal}
      isCanClose={true}
      showCloseIcon={true}
      onAfterClose={() => handleClose()}
      title="Proyeccion de Flujo de Caja"
    >
      <ModalContent>
        {!validatePan && 
          (<ButtonToPrint
            cashflowInfo={cashflowInfo}
            year={year}
            price={price}
            discount={discount}
            excess={excess}
            activeLeasing={activeLeasing}
            vpnSolarSystem={vpnSolarSystem}
            vpnTraditionalEnergy={vpnTraditionalEnergy}
            vpnProject={vpnProject}
            isEpm={isEpm}
          />
          )
        }
        {
          activeLeasing ?
            <LeasingAnalysisTable
              cashflowInfo={cashflowInfo}
              year={year}
              activeLeasing={activeLeasing}
              vpnSolarSystem={vpnSolarSystem}
              vpnTraditionalEnergy={vpnTraditionalEnergy}
              vpnProject={vpnProject}
            /> :
            <FinancialAnalysisTable
              cashflowInfo={cashflowInfo}
              year={year}
            // activeLeasing={activeLeasing}
            />
        }
      </ModalContent>
    </StaticDialog>
  );
}

const mapStateToProps = (state) => {
  return {
    cashflowInfo: state.chartsData.data.cashflow,
    activeLeasing: state.typeOperation.leasing
  };
};

export default connect(mapStateToProps, {})(FinancialAnalysis);
