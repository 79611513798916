import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Utils
import { leasingReturnData, EPCReturnData, panama } from "../../utils/handlers/handlers";

// Components
import DataCard from "../PartialComponents/DataCard";

const MobileInfoCard = ({ cardsInfo, activeLeasing, city }) => {
  const [returnData, setReturnData] = useState(EPCReturnData)
  const [cardsData, setCardsData] = useState(cardsInfo)

  useEffect(() => {
    const newState = cardsInfo.map(item => {
      return {...item}
    })
    setCardsData(newState)
  }, [cardsInfo, activeLeasing])
  
  const numberFormat = Intl.NumberFormat("de-DE");

  const renderVPNCard = (item) => {
    const validatePan = city.includes("panama");
    const style = {
      backgroundColor: `${item.bgColor}`
    };
    const name = cardsData[3].name;
    let value = cardsData[3].value;
    const units = validatePan ? "USD" : "COP"

    value = (value * 1000) / 1000000;
    value = numberFormat.format(Math.round(value));
    value = `${value}${validatePan ? 'K' : 'M'}`;

    return (
      <DataCard
        key={3}
        style={style}
        item={item}
        value={value}
        name={name}
        units={units}
        containerClass="w-full h-24 rounded-lg"
        valuesClass="text-center text-white w-full h-full flex flex-col"
        iconStyle={iconStyle}
        iconSize="5x"
      />
    )
  }

  const iconStyle = {
    fontSize: "1.2rem",
    position: "relative",
    top: "-6.2rem",
    left: "0.5rem",
  };
  
  return (
    <section
      id="returnInvestment"
      className="returnInvestment h-screen w-5/6 mx-auto flex flex-col justify-center space-y-6 items-center pt-36"
    >
      {returnData.map((item, index) => {
        const style = {
          backgroundColor: `${item.bgColor}`
        };
        const name = cardsData[index].name;
        let value = cardsData[index].value;
        let units = ""

        if (name === "TIR") {
          if (value !== "No disponible") {
            value = numberFormat.format(value);
            value = `${value} %`;
          }
        }

        if (name === "VPN" || name.includes("Cuota") || name.includes('compra') ) {
          if (value !== "No disponible") {
            const validatePan = city.includes("panama");
            value = (value * 1000) / 1000000;
            value = numberFormat.format(Math.round(value));
            value = `${value}${validatePan ? 'K' : 'M'}`;
            units = validatePan ? "USD" : "COP"
          }
        }

        if(name.includes('Leasing')) {
          units = "Meses"
        }

        return (
          <DataCard
            key={index}
            style={style}
            item={item}
            value={value}
            name={name}
            units={units}
            containerClass="w-full h-24 rounded-lg"
            valuesClass="text-center text-white w-full h-full flex flex-col"
            iconStyle={iconStyle}
            iconSize="5x"
          />
        );
      })}
      {
        cardsData.length === 4 && renderVPNCard(leasingReturnData[3])
      }
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    cardsInfo: state.chartsData.data.cardsInfo,
    activeLeasing: state.typeOperation.leasing,
    city: state.globalData.data.cotizacion.ciudad,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileInfoCard);
