import { FETCH_FILE_DATA_SUCCESS, FETCH_FILE_DATA_FAILURE} from './filesDataTypes';
import { getQuoteVersion } from '../../handlers/handlers';
import { GETFile } from '../../handlers/WebServices';

export const fetchFileDataSuccess = (data) => {
    return {
        type: FETCH_FILE_DATA_SUCCESS,
        payload: data
    }
}

export const fetchFileDataFailure = (error) => {
    return {
        type: FETCH_FILE_DATA_FAILURE,
        payload: error
    }
}

export const getFile = (key) => {
    const version = getQuoteVersion();
    const requestData = {
        key
    };

    return (dispatch) => {
        dispatch(fetchFileDataSuccess(false));
        GETFile('POST', requestData, `get-file/${version}`)
        .then(response => {
            const data = response.data;
            let binaryData = [];
            binaryData.push(data);

            const blob = new Blob(binaryData, {type: "application/pdf"})
            const fileURL = window.URL.createObjectURL(blob);
            
            window.open(fileURL);
            dispatch(fetchFileDataSuccess(true));
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchFileDataFailure(error.message))
        })
    }
}