import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Styled Components
import Button from "react-bootstrap/Button";
import FinancialAnalysis from "../PartialComponents/FinancialAnalysis";

// components
import Slider from "../PartialComponents/Slider";

// Utils
import { fetchChartsData, fetchLeasingChartsData } from "../../utils/redux";
import { addNewTag } from "../../utils/handlers/zohoIntegration";
import { updateVariableData } from "../../utils/redux/variablesData/variablesDataActions";

const MobileSlider = ({
  calculationData,
  subtotal,
  iva,
  fetchLeasingChartsData,
  getChartsData,
  _idZoho,
  // yearEnergy,
  tiempo_solar,
  potencia_KWP,
  setIsLoading,
  variableData,
  activeLeasing,
  quoteType, 
  city
}) => {
  const [year, setYear] = useState(15);
  const [price, setPrice] = useState(5.2);
  const [discount, setDiscount] = useState(10);
  const [excess, setExcess] = useState(20);
  const [paymentTax, setPaymentTax] = useState(6.78);
  const [openModal, setopenModal] = useState(false);
  const [userInteractions, setUserInteractions] = useState(0);
  const [updateBenefits, setUpdateBenefits] = useState(false)
  const validatePan = "panama".includes(city)

  setTimeout(()=>{
    if(!updateBenefits){
      setUpdateBenefits(true)
    }
  },2000)

  useEffect(() => {
    calculateCharts();
    variableData({ year, price, discount, excess, paymentTax });
  }, [price, discount, year, excess, activeLeasing, updateBenefits]);

  const calculateCharts = () => {
    if(activeLeasing) {
      const yearEnergy = tiempo_solar * potencia_KWP * 365
      fetchLeasingChartsData(
        discount,
        subtotal,
        iva,
        excess,
        paymentTax,
        calculationData.costo_energia,
        yearEnergy,
        calculationData.costo_mantenimiento,
        year,
        calculationData,
        price / 100
      )
    } else {
      getChartsData(
        calculationData,
        price / 100,
        discount / 100,
        year,
        subtotal,
        iva,
        excess / 100,
        quoteType, 
        city
      );
    }
    setUserInteractions(userInteractions + 1);
    if (userInteractions > 1) {
      addNewTag(_idZoho, "ErQte Interés Gráf. ROI");
    }
  };

  const handleModalClose = () => {
    setopenModal(!openModal);
    addNewTag(_idZoho, "ErQte Interés An Financ");
  };

  return (
    <section className="returnInvestment md:w-1/4 px-3 md:px-0">
      <h3 className="mb-6">Variables:</h3>
      <div className="sliders-container w-full mb-6 overscroll-contain">
        {
          activeLeasing && (
            <Slider
              id="slider-tax"
              min={0}
              max={30}
              defaultValue={paymentTax}
              action={setPaymentTax}
              actionLoading={setIsLoading}
              label="Tasa EA"
              units="%"
              step={0.1}
            />
          )
        }
        <Slider
          id="slider-year"
          min={0}
          max={25}
          defaultValue={year}
          action={setYear}
          label="Horizonte del Proyecto"
          units="años"
          step={1}
        />
        <Slider
          id="slider-price"
          min={1}
          max={30}
          defaultValue={price}
          action={setPrice}
          actionLoading={setIsLoading}
          label="Crecimiento precio kWh"
          units="%"
          step={0.1}
        />
        <Slider
          id="slider-discount"
          min={0}
          max={30}
          defaultValue={discount}
          action={setDiscount}
          actionLoading={setIsLoading}
          label="Tasa de descuento / Oportunidad"
          units="%"
          step={0.1}
        />
      {
        !validatePan && (
        <>
          <Slider
          id="slider-exce"
          min={0}
          max={100}
          defaultValue={excess}
          action={setExcess}
          actionLoading={setIsLoading}
          label="Excedentes"
          units="%"
          step={1}
          
        />
        <p className="sliderClousure mt-4">* El porcentaje de excedentes y su impacto en el flujo de caja corresponde a una estimacion</p>
        </>
        )
      }

      </div>

      <Button bsPrefix="download-button" onClick={handleModalClose}>
        Análisis Financiero
      </Button>
      <FinancialAnalysis
        openModal={openModal}
        handleClose={handleModalClose}
        year={year}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  const source = state.globalData.data["retorno de inversion"]["chartData"];
  return {
    calculationData: source["chart data"]["calculation data"],
    subtotal: state.globalData.data["costo de inversion"].subtotal,
    iva: state.globalData.data["costo de inversion"].iva,
    _idZoho: state.globalData.data.cotizacion._idZoho,
    // yearEnergy: state.globalData.data["datos de calculo"].datos[1].value * 12,
    tiempo_solar: state.globalData.data["retorno de inversion"].chartData["chart data"]["calculation data"].tiempo_solar,
    potencia_KWP: state.globalData.data["retorno de inversion"].chartData["chart data"]["calculation data"].potencia_KWP,
    activeLeasing: state.typeOperation.leasing,
    quoteType: state.globalData.data.cotizacion.tipo,
    city: state.globalData.data.cotizacion.ciudad,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChartsData: (
      chartData,
      price,
      discount,
      year,
      subtotal,
      iva,
      excess,
      quoteType,
      city
    ) =>
      dispatch(
        fetchChartsData(chartData, price, discount, year, subtotal, iva, excess, quoteType, city)
      ),
    fetchLeasingChartsData: (tasa_oportunidad, subtotal, iva, excess, tax, energyCostCol, yearEnergy, maintenanceCost, year, calculationData, energy_cost_increase) => dispatch(fetchLeasingChartsData(tasa_oportunidad, subtotal, iva, excess, tax, energyCostCol, yearEnergy, maintenanceCost, year, calculationData, energy_cost_increase)),
    variableData: (item) => dispatch(updateVariableData(item))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileSlider);
