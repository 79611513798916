import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Utils
import { leasingReturnData, EPCReturnData, panama, formatNumber } from "../utils/handlers/handlers";
import { fetchChartsData, fetchLeasingChartsData } from "../utils/redux";
import { addNewTag } from "../utils/handlers/zohoIntegration";
import { updateVariableData } from "../utils/redux/variablesData/variablesDataActions";

// Styled Components
import Button from "react-bootstrap/Button";

// Partial Components
import DataCard from "./PartialComponents/DataCard";
import Slider from "./PartialComponents/Slider";
import ChartList from "./PartialComponents/ChartList";
import FinancialAnalysis from "./PartialComponents/FinancialAnalysis";

function RetornoInversion({
  cardsInfo,
  calculationData,
  fetchChartsData,
  fetchLeasingChartsData,
  subtotal,
  iva,
  _idZoho,
  // yearEnergy,
  tiempo_solar,
  potencia_KWP,
  variableData,
  activeLeasing,
  quoteType,
  city,
  isEpm
}) {
  const [year, setYear] = useState(25);
  const [price, setPrice] = useState(5.2);
  const [discount, setDiscount] = useState(10);
  const [excess, setExcess] = useState(()=> city.includes("panama") ? 0 : calculationData.excedentes);
  const [paymentTax, setPaymentTax] = useState(6.78);
  const [openModal, setopenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInteractions, setUserInteractions] = useState(0);
  const [cardsData, setCardsData] = useState(cardsInfo)
  const [returnData, setReturnData] = useState(EPCReturnData)

  const numberFormat = Intl.NumberFormat("de-DE");
  

  const calculateCharts = () => {
    if(activeLeasing) {
      const yearEnergy = tiempo_solar * potencia_KWP * 365
      fetchLeasingChartsData(
        discount,
        subtotal,
        iva,
        excess,
        paymentTax,
        calculationData.costo_energia,
        yearEnergy,
        calculationData.costo_mantenimiento,
        year,
        calculationData,
        price / 100
      )
    } else {
      fetchChartsData(
        calculationData,
        price / 100,
        discount / 100,
        year,
        subtotal,
        iva,
        excess / 100,
        quoteType,
        city
      );
    }
    setUserInteractions(userInteractions + 1);
    if (userInteractions > 1) {
      addNewTag(_idZoho, "ErQte Interés Gráf. ROI");
    }
  };

  useEffect(() => {
    calculateCharts();
    variableData({ year, price, discount, excess, paymentTax });
  }, [price, discount, year, excess, paymentTax, activeLeasing, subtotal]);

  const handleModalClose = () => {
    setopenModal(!openModal);
    addNewTag(_idZoho, "ErQte Interés An Financ");
  };
  
  useEffect(() => {
    const newState = cardsInfo.map(item => {
      return {...item}
    })
    setCardsData(newState)
  }, [cardsInfo, activeLeasing])

  const iconStyle = {
    fontSize: "1.2rem",
    position: "relative",
    top: "-6.2rem",
    left: "0.5rem"
  };

  const renderVPNCard = (item) => {
    const validatePan = city.includes("panama");
    const style = {
      backgroundColor: `${item.bgColor}`
    };
    const name = cardsData[3].name;
    let value = cardsData[3].value;
    const units = validatePan ? "USD" : "COP"

    value = (value * 1000) / 1000000;
    value = numberFormat.format(Math.round(value));
    value = `${value}${validatePan ? 'K' : 'M'}`;
    // units = "COP"

    return (
      <DataCard
        key={3}
        style={style}
        item={item}
        value={value}
        name={name}
        units={units}
        containerClass="w-full h-24 lg:h-20 2xl:h-32 rounded-lg"
        valuesClass="text-center text-white w-full h-full flex flex-col justify-around pt-3"
        iconStyle={iconStyle}
        iconSize="5x"
      />
    )
  }

  return (
    <section
      id="returnInvestment"
      className="returnInvestment flex flex-col justify-center w-screen h-screen lg:pt-12"
    >
      <div className="h-auto mt-24 lg:mt-28 2xl:mt-36 flex flex-col">
        {/* Info cards */}
        <div className={`w-11/12 h-auto ml-3 lg:mx-auto flex flex-col lg:flex-row gap-6`}>
          {returnData.map((item, index) => {
            const style = {
              backgroundColor: `${item.bgColor}`
            };
            const name = cardsData[index].name;
            let value = cardsData[index].value;
            let units = ""

            if (name === "TIR") {
              if (value !== "No disponible") {
                value = formatNumber(value);
                value = `${value} %`;
              }
            }

            if (name.includes("VPN") || name.includes("Cuota") || name.includes('compra')) {
              if (value !== "No disponible") {
                const validatePan = city.includes("panama");
                value = (value * 1000) / 1000000;
                value = numberFormat.format(Math.round(value));
                value = `${value}${validatePan ? 'K' : 'M'}`;
                units = validatePan ? "USD" : "COP"
              }
            }

            if (name.includes('Leasing')) {
              units = "Meses"
            }

            return (
              <DataCard
                key={index}
                style={style}
                item={item}
                value={value}
                name={name}
                units={units}
                containerClass="w-full h-24 lg:h-24 2xl:h-32 rounded-lg"
                valuesClass="text-center text-white w-full h-full flex flex-col justify-around pt-4"
                iconStyle={iconStyle}
                iconSize="5x"
              />
            );
          })}
          {
            cardsData.length === 4 && renderVPNCard(leasingReturnData[3])
          }
        </div>

        <div className="main-charts-container h-3/4 lg:h-auto w-11/12 mx-auto mt-6 2xl:mt-10 flex flex-col-reverse md:flex-row md:justify-center">
          {/* Sliders */}
          <div className="md:w-1/4 px-3 md:px-0 h-auto">
            <h3 className="mb-6">Variables:</h3>
            <div className="sliders-container w-full mb-6 overflow-y-hidden md:overflow-y-scroll 2xl:overflow-y-hidden overscroll-contain">
              {
                activeLeasing && (
                  <Slider
                    id="slider-tax"
                    min={0}
                    max={30}
                    defaultValue={paymentTax}
                    action={setPaymentTax}
                    actionLoading={setIsLoading}
                    label="Tasa EA"
                    units="%"
                    step={0.1}
                  />
                )
              }
              <Slider
                id="slider-year"
                min={0}
                max={25}
                defaultValue={year}
                action={setYear}
                label="Horizonte del Proyecto"
                units="años"
                step={1}
              />
              <Slider
                id="slider-price"
                min={1}
                max={30}
                defaultValue={price}
                action={setPrice}
                actionLoading={setIsLoading}
                label="Crecimiento precio kWh"
                units="%"
                step={0.1}
              />
              <Slider
                id="slider-discount"
                min={0}
                max={30}
                defaultValue={discount}
                action={setDiscount}
                actionLoading={setIsLoading}
                label="Tasa de descuento / Oportunidad"
                units="%"
                step={0.1}
              />
              {
                !city.includes("panama") && (
                  <Slider
                    id="slider-exce"
                    min={0}
                    max={100}
                    defaultValue={excess}
                    action={setExcess}
                    actionLoading={setIsLoading}
                    label="Excedentes"
                    units="%"
                    step={1}
                  />
                )
              }
              {
                !city.includes("panama") && (
                  <p className="sliderClousure mt-4">* El porcentaje de excedentes y su impacto en el flujo de caja corresponde a una estimacion</p>
                )
              }
            </div>
            <Button bsPrefix="download-button" onClick={handleModalClose}>
              Análisis Financiero
            </Button>
          </div>

          {/* Charts */}
          <div className="w-full lg:w-4/5 h-full md:ml-8">
            <ChartList
              isLoading={isLoading}
              // activeLeasing={activeLeasing}
              // setActiveLeasing={setActiveLeasing}
            />
          </div>
        </div>
      </div>
      {/* Financial Analysis */}
      <FinancialAnalysis
        openModal={openModal}
        handleClose={handleModalClose}
        year={year}
        price={price}
        discount={discount}
        excess={excess}
        // activeLeasing={activeLeasing}
        isEpm={isEpm}
        city={city}
      />
    </section>
  );
}

const mapStateToProps = (state) => {
  const source = state.globalData.data["retorno de inversion"]["chartData"];
  return {
    cardsInfo: state.chartsData.data.cardsInfo,
    calculationData: source["chart data"]["calculation data"],
    subtotal: state.globalData.data["costo de inversion"].subtotal,
    iva: state.globalData.data["costo de inversion"].iva,
    _idZoho: state.globalData.data.cotizacion._idZoho,
    // yearEnergy: state.globalData.data["datos de calculo"].datos[1].value * 12,
    tiempo_solar: state.globalData.data["retorno de inversion"].chartData["chart data"]["calculation data"].tiempo_solar,
    potencia_KWP: state.globalData.data["retorno de inversion"].chartData["chart data"]["calculation data"].potencia_KWP,
    activeLeasing: state.typeOperation.leasing,
    quoteType: state.globalData.data.cotizacion.tipo,
    city: state.globalData.data.cotizacion.ciudad,
    isEpm: state.globalData.data.cotizacion.isEpm
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChartsData: (
      chartData,
      price,
      discount,
      year,
      subtotal,
      iva,
      excess,
      quoteType,
      city,
      isEpm
    ) =>
      dispatch(
        fetchChartsData(chartData, price, discount, year, subtotal, iva, excess, quoteType, city, isEpm)
      ),
    fetchLeasingChartsData: (tasa_oportunidad, subtotal, iva, excess, tax, energyCostCol, yearEnergy, maintenanceCost, year, calculationData, energy_cost_increase) => dispatch(fetchLeasingChartsData(tasa_oportunidad, subtotal, iva, excess, tax, energyCostCol, yearEnergy, maintenanceCost, year, calculationData, energy_cost_increase)),
    variableData: (item) => dispatch(updateVariableData(item))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetornoInversion);
