import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import { addNewTag } from '../../utils/handlers/zohoIntegration';

export default function CollapseDiv(props) {
    
    const index = props.index;

    const style = {
        backgroundColor: `${props.item.bgColor}`
    }

    const toggle = (index) => {
        props.setActive(index)
        addNewTag(props._idZoho, 'ErQte Interés Tecnologí')
    }

    return (
        <div style={style} className="collapse w-11/12 lg:w-2/3 text-white rounded-xl mb-2 lg:mb-4">
            <div className="mx-6 my-2 flex flex-row justify-between">
                <h3 className="my-auto">{props.item.title}</h3>
                <button className={props.active === index ? "hidden" : "mx-2"} onClick={() => toggle(index)} >
                    <FontAwesomeIcon
                        icon={props.active === index ? faChevronUp : faChevronDown}
                        color="white"
                        size="lg"
                    />
                </button>
            </div>
            <div className={props.active === index  ? 'panel-open' : 'panel-close'}>
                <p>{props.item.content}</p>
                <div className="bottom-0 flex justify-end">
                    <button className="mx-2" onClick={() => toggle(-1)}>
                        <FontAwesomeIcon
                            icon={props.active === index ? faChevronUp : faChevronDown}
                            color="white"
                            size="lg"
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}