import React from "react";

import { Text, View, Image } from "@react-pdf/renderer";

import { styles } from "../../../utils/handlers/stylesPDF";
import { envImages, epmLogo, formatNumber } from "../../../utils/handlers/handlers";

const EnvironmentPdf = ({ data, isEpm }) => {
  const numberFormat = Intl.NumberFormat("de-DE");
  const environment = data["medio ambiente"];

  const firstRow = environment.slice(0, -1);
  const lastRow = environment.slice(-1);

  return (
    <>
    { isEpm &&
      <View style={styles.empIconContentAbsolute}>
        <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
      </View>
    }
    <View style={styles.containerEnv}>
      <Text style={styles.titleLeft}>Medio Ambiente</Text>
      <View style={styles.textStyle}>
        <View style={styles.containerEnvFollowed}>
          {/* {firstRow.map((datum, index) => {
            let value = datum.value;
            value = numberFormat.format(value);
            return (
              <View style={styles.containerEnvFirst} key={index}>
                <View style={styles.containerImageEnv}>
                  <Image style={styles.imageEnv} src={envImages[index]} />
                </View>
                <View style={styles.containerTextEnv}>
                  <Text>{value}</Text>
                  <Text>{datum.name}</Text>
                </View>
              </View>
            );
          })} */}
          <View style={styles.containerEnvFirst1}>
            <View style={styles.containerImageEnv1}>
              <Image style={styles.imageEnv} src={envImages[0]} />
            </View>
            <View style={styles.containerTextEnv1}>
              <Text>{formatNumber(firstRow[0].value)}</Text>
              <Text>{firstRow[0].name}</Text>
            </View>
          </View>
        </View>
        <View>
          {lastRow.map((datum) => {
            const index = 2;
            let value = datum.value;
            value = formatNumber(value);
            return (
              <View style={styles.containerEnvFirst1} key={index}>
                <View style={styles.containerImageEnv1}>
                  <Image style={styles.imageEnv} src={envImages[index]} />
                </View>
                <View style={styles.containerTextEnv1}>
                  <Text>{value}</Text>
                  <Text>{datum.name}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </View>
      <View style={styles.textContent}>
        <Text style={styles.point}>*</Text>
        <Text style={styles.text}>Aporte ambiental en el primer año del proyecto.</Text>
      </View>
    </View>
    </>
  );
};

export default EnvironmentPdf;
