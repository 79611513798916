import React from "react";
import { connect } from "react-redux";

// Components
import PieChart from "../PartialComponents/PieChart";
import PanelCard from "../PartialComponents/PanelCard";

const MobilePanelCard = ({ cards_data, solar_energy }) => {
  const cardsData = cards_data;
  const solarEnergy = Math.round(solar_energy.value);

  return (
    <section className="calculationData 2xl:h-80">
      <div className="h-72 mb-6 mx-auto 2xl:h-72 w-5/6">
        <PieChart />
        <h1 className="solar-energy text-center relative -top-52 mt-1 ml-2">
          {`${solarEnergy}%`}
        </h1>
      </div>
      <div className="2xl:h-72 lg:w-auto lg:mx-0">
        <PanelCard panels={cardsData.numPaneles} />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const source = state.globalData.data["datos de calculo"];
  return {
    cards_data: source,
    solar_energy: source["grafica"][1],
  };
};

export default connect(mapStateToProps, {})(MobilePanelCard);
