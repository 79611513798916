import React, { useState } from "react";

import { Document, Page } from "@react-pdf/renderer";

import HomePdf from "./PageView/HomePdf";
import CalculationDataPdf from "./PageView/CalculationDataPdf";
import TechnologyPdf from "./PageView/TechnologyPdf";
import InvestmentPdf from "./PageView/InvestmentPdf";
import ReturnInvestmentPdf from "./PageView/ReturnInvestmentPdf";
import EnvironmentPdf from "./PageView/EnvironmentPdf";
import ExperiencePdf from "./PageView/ExperiencePdf";
import NationalCoveragePdf from "./PageView/NationalCoveragePdf";
import MaintenanceCostPdf from "./PageView/MaintenanceCostPdf";
import EconomicProposal from "./PageView/EconomicProposal";
import ListingConditions from "./PageView/ListingConditions";

import { styles } from "../../utils/handlers/stylesPDF";
import { validateAllowView } from "../../utils/handlers/handlers";
import CommercialConditionsPdf from "./PageView/CommercialConditionsPdf";
import WarrantiesPdf from "./PageView/WarrantiesPdf";

const PagePdf = ({
  data,
  state,
  isExpired,
  allowedROI,
  id,
  version,
  standarPrice,
  investmentCost,
  isEpm,
  cashflowInfo,
  city
}) => {
  const [allowROI] = useState(validateAllowView(allowedROI));

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <HomePdf
          data={data}
          state={state}
          id={id}
          version={version}
          isExpired={isExpired}
          isEpm={isEpm}
        />
      </Page>
      {!isEpm && (
        <Page size="A4" orientation="landscape">
          <ExperiencePdf />
        </Page>
      )}
      {isEpm && (
        <Page size="A4" orientation="landscape" style={styles.page}>
          <NationalCoveragePdf isEpm={isEpm} />
        </Page>
      )}
      <Page size="A4" orientation="landscape" style={styles.page}>
        <CalculationDataPdf data={data} isEpm={isEpm} />
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <TechnologyPdf isEpm={isEpm} city={city} />
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <InvestmentPdf
          data={data}
          standarPrice={standarPrice}
          investmentCost={investmentCost}
          isEpm={isEpm}
          cashflowInfo={cashflowInfo}
          allowedROI={allowedROI}
          city={city}
        />
      </Page>
      {isEpm && data["retorno de inversion"].chartData["chart data"]["calculation data"]
        .costo_mantenimiento > 0 && (
        <Page size="A4" orientation="landscape" style={styles.page}>
          <MaintenanceCostPdf data={data} />
        </Page>
      )}
      {allowROI && (
        <Page size="A4" orientation="landscape" style={styles.page}>
          <ReturnInvestmentPdf
            data={data}
            state={state}
            id={id}
            version={version}
            isEpm={isEpm}
            city={city}
          />
        </Page>
      )}
      {isEpm && (
        <>
          {/* <Page size="A4" orientation="landscape" style={styles.page}>
            <EconomicProposal tableData={state.globalData.data.epmFinancing} />
          </Page> */}
          <Page size="A4" orientation="landscape" style={styles.page}>
            <CommercialConditionsPdf />
          </Page>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <WarrantiesPdf />
          </Page>
        </>
      )}
      <Page size="A4" orientation="landscape" style={styles.page}>
        <EnvironmentPdf data={data} isEpm={isEpm} />
      </Page>
      {isEpm && (
        <Page size="A4" orientation="landscape" style={styles.page}>
          <ListingConditions isPrequote={data.cotizacion.precotizacion} />
        </Page>
      )}
    </Document>
  );
};

export default PagePdf;
