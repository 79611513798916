import { FETCH_CONTRACT_DATA_SUCCESS, FETCH_CONTRACT_DATA_FAILURE} from './contractDataTypes';
import { SETData, GETFile } from '../../handlers/WebServices';
import { getQuoteVersion, getClientId } from '../../handlers/handlers';

export const fetchContractDataSuccess = (data) => {
    return {
        type: FETCH_CONTRACT_DATA_SUCCESS,
        payload: data
    }
}

export const fetchContractDataFailure = (error) => {
    return {
        type: FETCH_CONTRACT_DATA_FAILURE,
        payload: error
    }
}

export const createContractPreview = (signature, client, power, total, city, signDate, quoteType) => {
    const version = getQuoteVersion();
    const requestData = {
        signature,
        client,
        power,
        total,
        city,
        signDate,
        quoteType
    };
    return (dispatch) => {
        SETData(requestData, `contract-preview/${version}`)
        .then(response => {
            if (response.data != null){
                const key = response.data;
                const data = {
                    url: key,
                    loaded: false,
                    signed: false
                }
                dispatch(fetchContractDataSuccess(data));
                dispatch(getContractPreview(key));
            }
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchContractDataFailure(error.message))
        })
    }
}

export const getContractPreview = (key) => {
    const version = getQuoteVersion();
    const requestData = {
        key
    };
    return (dispatch) => {
        GETFile('POST', requestData, `get-file/${version}`)
        .then( async(response) => {
            const data = response.data;
            //console.log('Data:',await data.arrayBuffer());
            let fileURL;
            if(data.size > 0) {
                let binaryData = [];
                binaryData.push(data);
                
                const blob = new Blob(binaryData, { type: "application/pdf" })
                //console.log(blob);
                fileURL = window.URL.createObjectURL(blob);
            } else {
                fileURL = '';
                console.log('NO: ', data);
            }
            const file = {
                url: fileURL,
                loaded: true,
                signed: false
            }
            dispatch(fetchContractDataSuccess(file));
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchContractDataFailure(error.message))
        })
    }
}

export const signContract = (signature, client, power, total, city, signDate, quoteType) => {
    const version = getQuoteVersion();
    const id = getClientId();
    const requestData = {
        signature,
        client,
        power,
        total,
        city,
        signDate,
        quoteType
    };
    return (dispatch) => {
        SETData(requestData, `sign-contract/${id}/${version}`)
        .then(response => {
            if (response.data != null){
                const url = response.data;
                const data = {
                    url,
                    loaded: false,
                    signed: false
                }
                dispatch(fetchContractDataSuccess(data));
                dispatch(getContract(url));
            }
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchContractDataFailure(error.message))
        })
    }
}

export const getContract = (key) => {
    const version = getQuoteVersion();
    const requestData = {
        key
    };
    return (dispatch) => {
        GETFile('POST', requestData, `get-file/${version}`)
        .then(response => {
            const data = response.data;
            let fileURL;
            if(data.size > 0) {
                let binaryData = [];
                binaryData.push(data);
                
                const blob = new Blob(binaryData, {type: "application/pdf"})
                fileURL = window.URL.createObjectURL(blob);
            } else {
                fileURL = '';
                console.log('NO: ', data);
            }
            const file = {
                url: fileURL,
                loaded: false,
                signed: true
            }
            dispatch(fetchContractDataSuccess(file));
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchContractDataFailure(error.message))
        })
    }
}

export const cleanContractInfo = () => {
    const data = {
        url: '',
        loaded: true,
        signed: false
    };

    return (dispatch) => {
        dispatch(fetchContractDataSuccess(data));
    }
}