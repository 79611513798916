import React, { useState } from "react";
import CollapseDiv from "./PartialComponents/CollapseDiv";
import { connect } from "react-redux";
import { techData } from "../utils/handlers/handlers";
import full_image from "../files/images/full_image.svg";

function Tecnologia({ _idZoho, isEpm, city }) {
  const [active, setActive] = useState(0);

  const setImage = () => {
    if (active > -1) {
      return filteredTechData[active].bgImage;
    } else {
      return full_image;
    }
  };

  const filteredTechData = city === "panama" ? techData.slice(0, 2) : techData;

  return (
    <section id="technology" className="technology flex h-screen w-screen z-10">
      <div className=" flex flex-col-reverse lg:flex-row items-center">
        <div className="collapse-container lg:w-3/6 h-2/3 lg:h-2/3 mt-0 flex flex-col items-center justify-center">
          {/* {
            !isEpm ? techData.map((item, index) => {
                return (
                    <CollapseDiv
                        key={index}
                        item={item}
                        index={index}
                        active={active}
                        setActive={setActive}
                        _idZoho={_idZoho}
                    />
                )
            }) : techData.slice(0, techData.length-1).map((item, index) => {
              const itemView = {...item, title: item.titleEpm};
              return (
                  <CollapseDiv
                      key={index}
                      item={itemView}
                      index={index}
                      active={active}
                      setActive={setActive}
                      _idZoho={_idZoho}
                  />
              )
          })
          } */}
          {Array.isArray(filteredTechData) && filteredTechData.length > 0 && filteredTechData.map((item, index) => (
            <CollapseDiv
              key={index}
              item={item}
              index={index}
              active={active}
              setActive={setActive}
              _idZoho={_idZoho}
            />
          ))}
        </div>
        <div className="lg:w-3/6 h-2/3 flex flex-row justify-center lg:justify-start">
          <img
            className="bgImage w-3/4 h-2/3 lg:h-full 2xl:h-3/4 mt-auto"
            src={setImage()}
            alt="Tech"
          />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    _idZoho: state.globalData.data.cotizacion._idZoho,
    isEpm: state.globalData.data.cotizacion.isEpm,
    city: state.globalData.data.cotizacion.ciudad,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tecnologia);
