import React from 'react'

export default function OtherConsiderations({ considerations }) {
    return (
        <div>
            <div  className="w-11/12 mx-auto">
                <h1 className="term w-full text-center mb-6">CONSIDERACIONES</h1>
                    {
                        considerations && considerations.map((term, index) => (
                            <p key={index} className="text-justify">{index + 1}. {term}<br/><br/></p>
                        ))
                    }
            </div>
        </div>
    )
}
