import React, { useEffect } from "react";
import { connect } from "react-redux";

// Utils
import { envImages, formatNumber } from "../utils/handlers/handlers";
import { addNewTag } from "../utils/handlers/zohoIntegration";

// Partials Components
import EnvironmentCard from "./PartialComponents/EnvironmentCard";

function MedioAmbiente({ environment, _idZoho }) {
  // const numberFormat = Intl.NumberFormat("de-DE");

  const firstRow = environment.slice(0, -1);
  const lastRow = environment.slice(-1);

  useEffect(() => {
    addNewTag(_idZoho, "ErQte Interés Med Ambient");
  }, [_idZoho]);

  return (
    <section
      id="medio-ambiente"
      className="medio-ambiente grid items-center w-5/6 lg:w-screen mx-auto pt-44"
    >
      <div className="w-auto lg:w-4/5 lg:mx-auto">
        {/* <div className="grid lg:grid-cols-2 lg:gap-x-12">
          {firstRow.map((datum, index) => {
            let value = datum.value;
            value = numberFormat.format(value);

            return (
              <EnvironmentCard
                key={index}
                containerClass="w-full h-40 2xl:h-56 mx-auto mt-5 lg:mt-10 rounded-lg"
                valuesClass="text-center text-white w-full h-full flex flex-col justify-around ml-10 lg:ml-16"
                image={envImages[index]}
                name={datum.name}
                value={value}
              />
            );
          })}
        </div> */}
        <div className="grid lg:grid-cols-1">
          <h1 className="font-bold text-2xl lg:text-4xl text-font-greyLight">Medio Ambiente</h1>
        </div>
        <div className="grid lg:grid-cols-1">
          <EnvironmentCard
            containerClass="w-full h-40 2xl:h-56 mx-auto mt-5 lg:mt-10 rounded-lg"
            valuesClass="text-center text-white w-full h-full flex flex-col justify-around ml-10 lg:ml-0"
            image={envImages[0]}
            name={firstRow[0].name}
            value={formatNumber(firstRow[0].value)}
          />
          {lastRow.map((datum) => {
            const index = 2;

            let value = datum.value;
            value = formatNumber(value);

            return (
              <EnvironmentCard
                key={index}
                containerClass="w-full h-40 2xl:h-56 mx-auto mt-5 lg:mt-10 rounded-lg"
                valuesClass="text-center text-white w-full h-full flex flex-col justify-around ml-10 lg:ml-0"
                image={envImages[index]}
                name={datum.name}
                value={value}
              />
            );
          })}
        </div>
        <div className="grid lg:grid-cols-1">
          <p className="text-justify lg:w-2/3 lg:mb-0 text-font-greyLight pt-2">* Aporte ambiental en el primer año del proyecto.</p>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    environment: state.globalData.data["medio ambiente"],
    _idZoho: state.globalData.data.cotizacion._idZoho,
  };
};

export default connect(mapStateToProps, {})(MedioAmbiente);
