import React from "react";

import { Text, View, Image } from "@react-pdf/renderer";

// utils
import { mapColEpm2, epmLogo } from "../../../utils/handlers/handlers";

import { styles } from "../../../utils/handlers/stylesPDF";

const NationalCoveragePdf = () => {
  const data = [
    {
      value: "+44 Mwp",
      name: "Instalados"
    },
    {
      value: "+627",
      name: "Instalaciones"
    },
    {
      value: "+498",
      name: "Hogares"
    },
    {
      value: "+27",
      name: "Empresas"
    },
    {
      value: "+41",
      name: "Grandes Clientes"
    },
    {
      value: "+9655",
      name: "Ton CO2 Evitadas al año 2024"
    }
  ];
  return (
    <View style={styles.nationalCoverageEpm}>
      <View>
        <Image style={styles.mapEmp} src={mapColEpm2} alt="MapColombia" />
      </View>
      <View>
        {data.map(({ value, name }, i) => (
          <View key={i} style={styles.itemsTextNational}>
            <Text style={styles.nationalTextValue}>{value}</Text>
            <Text>{name}</Text>
          </View>
        ))}
        <View style={styles.empIconContent}>
          <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
        </View>
        <Text style={styles.nationalTextTitle}>Cobertura nacional</Text>
      </View>
    </View>
  );
};

export default NationalCoveragePdf;
