import { FETCH_VERSION_DATA_SUCCESS, FETCH_VERSION_DATA_FAILURE} from './versionsDataTypes';

const initialState = {
    data: {},
    error: ''
}

const versionsDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_VERSION_DATA_SUCCESS:
            return {
                data: action.payload,
                error: ''
            }
            case FETCH_VERSION_DATA_FAILURE:
                return {
                    data: [],
                    error: ''
                }
            default: return state
    }
}

export default versionsDataReducer;