import axios from 'axios';
import config from '../../config/config.json';

const base_url  = config.url;

const GETData = async (endpoint) => {
    return new Promise( async (resolve) => {
        const response = await axios.get(`${base_url}/api/${endpoint}`)
    
        if (response.data) {
            resolve(response)
            
        } else {
            return null;
        }
    })
}

const SETData = async (params, endpoint) => {
    return new Promise( async (resolve) => {
        const response = await axios.post(`${base_url}/api/${endpoint}`, params)
    
        if (response.data) {
            resolve(response)
            
        } else {
            return null;
        }
    })
}

const GETFile = async (method, params, endpoint) => {
    return new Promise( async (resolve) => {
        const response = await axios({
            method: method,
            url: `${base_url}/api/${endpoint}`,
            data: params,
            responseType: 'blob'
        })
    
        if (response.data) {
            resolve(response)
            
        } else {
            return null;
        }
    })
}

export { GETData, SETData, GETFile };