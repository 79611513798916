import React from 'react'
import { paymentTime, comercialConsiderations, TPT_Pan } from '../../utils/handlers/handlers';

export default function ComercialConsiderations({ guarantee, city }) {

    const considerations = comercialConsiderations(guarantee, city);

    return (
        <div className="w-full flex flex-col justify-center mb-6">
            {/* PAYMENT */}
            <div className="mb-6">
                <h1 className="term w-full text-center mb-3">TIEMPO Y FORMA DE PAGO</h1>
                {city !== "panama" ? (
                    <>
                        <img className="mx-auto" src={paymentTime} alt="payment_time" />
                    </>
                ) : (
                    <>
                        <img className="mx-auto" src={TPT_Pan} alt="payment_time" />
                    </>
                )}
            </div>
            {/* GUARANTEE */}
            <div className="w-11/12 mx-auto">
                <h1 className="term w-full text-center mb-3">GARANTÍAS</h1>
                {
                    considerations.map((item, index) => (
                        <p key={index} className="text-justify">{index + 1}. {item}<br/><br/></p>
                    ))
                }
            </div>
        </div>
    )
}
