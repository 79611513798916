import { FETCH_CHARTS_DATA_SUCCESS, FETCH_CHARTS_DATA_FAILURE } from './chartsDataTypes';
import data from '../../handlers/chartsData.json';

const initialState = {
    data: data,
    error: ''
}

const chartsDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_CHARTS_DATA_SUCCESS:
            return {
                data: action.payload,
                error: ''
            }
        case FETCH_CHARTS_DATA_FAILURE:
            return {
                data: [],
                error: ''
            }
        default: return state
    }
}

export default chartsDataReducer;