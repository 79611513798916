import React from "react";
import { connect } from "react-redux";

import DataCard from "../PartialComponents/DataCard";

// Utils
import { investmentData } from "../../utils/handlers/handlers";

const MobileDataCards = ({ table_data, cards_data, solar_energy }) => {
  const cardsData = cards_data;
  const numberFormat = Intl.NumberFormat("de-DE");
  return (
    <section className="calculationData h-auto w-5/6 mx-auto mt-4 grid gap-4">
      {investmentData.map((item, index) => {
        const style = {
          backgroundColor: `${item.bgColor}`,
        };

        const iconStyle = {
          fontSize: "1.5rem",
          position: "relative",
          top: "-7.9rem",
        };

        let value = cardsData.datos[index].value;
        value = numberFormat.format(value);
        const name = cardsData.datos[index].name;
        const units = cardsData.datos[index].units;

        return (
          <DataCard
            key={index}
            style={style}
            item={item}
            value={value}
            name={name}
            units={units}
            containerClass="w-full h-24 2xl:h-36 rounded-lg mt-2 lg:mt-0"
            valuesClass="mt-1 2xl:mt-0 text-center text-white w-full h-full flex flex-col justify-around"
            iconStyle={iconStyle}
            iconSize="6x"
          />
        );
      })}
    </section>
  );
};

const mapStateToProps = (state) => {
  const source = state.globalData.data["datos de calculo"];
  return {
    table_data: state.globalData.data["cotizacion estandar"],
    cards_data: source,
    solar_energy: source["grafica"][1],
  };
};

export default connect(mapStateToProps, {})(MobileDataCards);
