import React, { useEffect, useState } from "react";

import { PDFDownloadLink } from "@react-pdf/renderer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getClientId, getQuoteVersion } from "../../utils//handlers/handlers";

import PagePdf from "../Doc/PagePdf";
import { connect } from "react-redux";

const ButtonToPrintPage = ({ isExpired, data, state, allowedROI, standarPrice, investmentCost, isEpm, cashflowInfo, city }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDownloadVisible, setIsDownloadVisible] = useState(false);
  const id = getClientId();
  const version = getQuoteVersion();

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    // Configura un temporizador para hacer visible el botón de descarga después de 3 segundos
    const timer = setTimeout(() => {
      setIsDownloadVisible(true);
    }, 2500);

    // Limpia el temporizador si el componente se desmonta
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!isVisible && isDownloadVisible && (
        <div className="fixed bottom-4 md:bottom-8 right-2 flex justify-between px-3 md:px-10 z-10">
          <button
            type="button"
            className="bg-font-green h-14 w-14 lg:h-16 lg:w-16 rounded-lg shadow-2xl"
          >
            <PDFDownloadLink
              fileName={`${id}/${version}/quote.pdf`}
              document={
                <PagePdf
                  data={data}
                  state={state}
                  isExpired={isExpired}
                  allowedROI={allowedROI}
                  id={id}
                  version={version}
                  standarPrice={standarPrice}
                  investmentCost={investmentCost}
                  isEpm={isEpm}
                  cashflowInfo={cashflowInfo}
                  city={city}
                />
              }
              className="cursor-pointer w-full h-full flex justify-center items-center text-xl"
            >
              <FontAwesomeIcon
                className="h-1/2 w-1/2"
                icon={faDownload}
                color="white"
              />
            </PDFDownloadLink>
          </button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const data = state.globalData.data;
  return {
    data,
    state,
    allowedROI: data["retorno de inversion"].allowROI,
    standarPrice: data["cotizacion estandar"],
    investmentCost: data["costo de inversion"],
    isEpm: data.cotizacion.isEpm,
    cashflowInfo: state.chartsData.data.cashflow,
    city: state.globalData.data.cotizacion.ciudad
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonToPrintPage);

// export default ButtonToPrintPage;
