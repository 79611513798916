import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

// Styled components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import Select from "react-select";

// Utils
import {
  ercoLogo,
  epmLogo,
  adviserPhoto,
  epmAdviserPhoto,
  getDate,
  selectStyles, 
  lightBulb,
  panama,
  getFormattedDate
} from "../utils/handlers/handlers";
import { fetchAdviserData, fetchQuoteVersions } from "../utils/redux";
import config from "../config/config.json";

function Home({
  cliente,
  currentVersion,
  fecha_expiracion,
  fetchAdviserData,
  adviser,
  id,
  fetchQuoteVersions,
  quoteVersions,
  designer,
  prequote,
  isEpm,
  city,
  defaultAdviserPhoto,
}) {
  useEffect(() => {
    if (id && currentVersion) {
      fetchAdviserData(id, currentVersion);
      fetchQuoteVersions(id);
    }
  }, [fetchAdviserData, fetchQuoteVersions, id, currentVersion]);

  const [versions, setVersions] = useState([]);
  const [countryCode] = useState(city.includes("panama") ? 507 : 57);
  const source = useSelector((state) => state.globalData.data);

  const quoteDate = getFormattedDate(source.createdAt)

  useEffect(() => {
    if (quoteVersions) {
      setVersions(quoteVersions);
    }
  }, [quoteVersions]);

  const getNewQuote = (requestedVersion) => {
    if (requestedVersion !== currentVersion) {
      const baseUrl = `${config.url}/${id}/${requestedVersion}`;
      window.location.href = baseUrl;
    }
  };
  
  return (
    <section
      id="home"
      className="home flex w-screen  lg:h-screen flex-col mb-20"
    >
      <div className="h-auto mt-24 lg:mt-28 2xl:mt-28 ml-2 lg:ml-32 text-font-greyLight z-10">
        <div className="container ml-2 mt-4 lg:mt-6 lg:mb-9 w-auto ">
          <h1 className="font-bold lg:text-left text-2xl lg:text-4xl">
            Hola, {cliente}
          </h1>
          {
            !isEpm && (
              <>
                <h2 className="text-xl font-semibold lg:text-3xl mt-8">
                  Con el Ecosistema de{" "}
                  <span className="text-font-green">Energía Erco</span>,
                </h2>
              <h2 className="text-xl font-semibold lg:text-3xl">
                Tienes el control de tu{" "}
                <span className="text-font-yellow">Energía</span>
              </h2>
            </>
          )}
          <br />
          {versions.length > 0 ? (
            <div className="flex flex-row items-center">
              <p className="text-lg lg:text-xl mr-4 lg:mr-8">Versión: </p>
              <Select
                className="w-20 lg:w-24"
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                name="versions"
                options={versions}
                onChange={(option) => getNewQuote(option.value)}
                placeholder={currentVersion}
                styles={selectStyles}
              />
            </div>
          ) : (
            <p>Versión {currentVersion}</p>
          )}
          <br />
          {
            prequote ?
            <p className="text-lg lg:text-xl">
              Precotización inicial, la propuesta puede variar en la cotización formal.
            </p> :
            <p className="text-lg lg:text-xl">
              Fecha de creación de la propuesta: {quoteDate}
              <br />
              Válida hasta: {getDate(fecha_expiracion)}
            </p>
          }
        </div>
         <div className="mt-6">
          <div className="flex space-x-2 items-center lg:space-x-6">
            <div className="text-center ml-2 lg:ml-0 text-lg">
              <img
                className="w-24 h-24 rounded-full"
                src={adviser.imagen ? adviser.imagen : defaultAdviserPhoto}
                alt="Asesor"
              />
              <h6>Asesor</h6>
            </div>
            {!adviser.nombre ? (
              <div className="text-sm md:text-lg animate-pulse">
                <p>
                  <span className="bg-gray-300 h-3 w-48 inline-block rounded-full"></span>
                  <span className="block bg-gray-300 h-3 w-32 mt-1 rounded-full"></span>
                </p>
                <p>
                  <span className="bg-gray-300 h-3 w-6 rounded-full"></span>
                  <span className="ml-2 lg:ml-3 bg-gray-300 h-3 w-32 inline-block rounded-full"></span>
                </p>
                <p>
                  <span className="bg-gray-300 h-3 w-6 rounded-full"></span>
                  <span className="ml-2 bg-gray-300 h-3 w-20 inline-block rounded-full"></span>
                </p>
              </div>
            ) : (
              <div className="text-sm md:text-lg">
                <p>
                  {adviser.nombre}
                  <span className="block">{adviser.cargo}</span>
                </p>
                <p>
                  <a
                    href={`mailto:${adviser.email}?subject=Duda sobre cotización`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      color="#92BD21"
                      size="xl"
                    />
                  </a>
                  <span className="ml-2 lg:ml-3">{adviser.email}</span>
                </p>
                <p>
                  <a
                    href={`https://wa.me/${countryCode}${adviser.wapp}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      color="#92BD21"
                      size="lg"
                    />
                  </a>
                  <span className="ml-2">+{countryCode} {adviser.wapp}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="grid w-screen bottom-0 invisible lg:visible">
        <img
          className="justify-self-center bottom-8 absolute h-10 lg:h-16 z-10"
          src={isEpm ? epmLogo : ercoLogo}
          alt="Logo"
        />
        {lightBulb}
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const source = state.globalData.data;
  const defaultAdviserPhoto = source.cotizacion.isEpm ? epmAdviserPhoto : adviserPhoto;
  return {
    cliente: source.cliente.nombre,
    currentVersion: source.version,
    fecha_expiracion: source.cotizacion.fecha_expiracion,
    adviser: state.adviserData.data,
    id: state.globalData.data._idCliente,
    quoteVersions: state.versionsData.data.versions,
    designer: source.designer,
    prequote: source.cotizacion.precotizacion,
    isEpm: source.cotizacion.isEpm,
    city: state.globalData.data.cotizacion.ciudad,
    defaultAdviserPhoto
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAdviserData: () => dispatch(fetchAdviserData()),
    fetchQuoteVersions: () => dispatch(fetchQuoteVersions())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
