import React, { useState, useEffect } from 'react';

// Utils
import { addNewTag } from '../../utils/handlers/zohoIntegration';

// Styled components
import { ModalContent, StaticDialog } from 'react-st-modal';
import Button from 'react-bootstrap/Button';
import Loader from "react-loader-spinner";

export default function MessageModal({ getContract, contractKey, openMessageModal, setOpenMessageModal, contract, _idZoho }) {

    const [isLoading, setIsLoading] = useState(true);
    const [contractUrl, setContractUrl] = useState('');

    const openContract = () => {
        addNewTag(_idZoho, 'ErQte Clic Revisa tu Cot');
        setIsLoading(true)
        getContract(contractKey);
        window.open(contractUrl);
        window.location.reload();
    }

    useEffect(() => {
        if(contract.url.includes('http')) {
            setIsLoading(false);
            setContractUrl(contract.url)
        }
    }, [contract])

    const closeMessage = () => {
        setOpenMessageModal(false);
        window.location.reload();
    }

    return (
        <StaticDialog
            className="message-modal"
            isOpen={openMessageModal}
            isCanClose={false}
            showCloseIcon={true}
            onAfterClose={() => closeMessage()}
            title="Confirmacion"
        >
            <ModalContent>
                <div className="w-full my-4">
                    <h1 className="welcome w-full text-center text-3xl">¡ Bienvenido a ERCO !</h1>
                    <p className="w-5/6 mt-4 mx-auto text-center">Tu contrato ha sido creado exitosamente, en un momento tu asesor se comunicara contigo.</p>
                    <div className="w-full mt-14 flex flex-col justify-center">
                        {
                            isLoading ?
                            <Loader
                                className="w-11 mx-auto mt-2"
                                type="TailSpin"
                                color="#92BD21"
                                width={45}
                                height={45}
                            /> :
                            <Button
                                className="w-48 h-12 mx-auto rounded-lg text-xl approve"
                                onClick={() => openContract()}
                            >
                                Revisa tu contrato
                            </Button>
                        }
                    </div>
                </div>
            </ModalContent>
        </StaticDialog>
    )
}
