import React from "react";

import { Text, View, Image } from "@react-pdf/renderer";

import { styles } from "../../../utils/handlers/stylesPDF";

import { epmLogo, techData } from "../../../utils/handlers/handlers";

import full_image from "../../../files/images/full_image.jpg";

const TechnologyPdf = ({ isEpm, city }) => {
  const filteredTechData = city === "panama" ? techData.slice(0, 2) : techData;

  return (
    <>
    { isEpm &&
      <View style={styles.empIconContentAbsolute}>
        <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
      </View>
    }
    <View style={{ marginTop: 90 }}>
      <Text style={styles.titleLeft}>Tecnología</Text>
      <View style={styles.containerTech}>
        <View style={styles.textTech}>
          {filteredTechData.map(({ index, title, titleEpm, content }, i, array) => (
            <View
              key={index}
              style={
                i === 0
                  ? styles.textTitleGreen
                  : i === 1
                  ? styles.textTitleYellow
                  : styles.textTitleGreyLaight
              }
            >
              {isEpm && i === array.length - 1 ? null : (
                <>
                  <Text style={styles.textTitleAll}>{isEpm ? titleEpm : title}</Text>
                  <Text style={styles.textSpacingTop}>{content}</Text>
                </>
              )}
            </View>
          ))}
        </View>
        <Image style={styles.imageTech} src={full_image} alt="imagen" />
      </View>
    </View>
    </>
  );
};

export default TechnologyPdf;
