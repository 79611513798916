import React from "react";

import { Text, View, Image, Svg, Path } from "@react-pdf/renderer";

import { styles } from "../../../utils/handlers/stylesPDF";
import { epmLogo, formatCurrency, formatNumber } from "../../../utils/handlers/handlers";

const InvestmentPdf = ({
  standarPrice,
  investmentCost,
  isEpm,
  cashflowInfo,
  allowedROI,
  city,
  data
}) => {
  const table_data = standarPrice;
  const system_index = table_data.findIndex((dataS) =>
    dataS["Descripción"].includes("Sistema Solar")
  );

  const tableData = table_data.slice(system_index);
  const cash_data = investmentCost;

  const cop_format = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumSignificantDigits: 12,
  });
  const isPanama = city.includes("panama");
  const value = isPanama ? formatCurrency(cash_data["Precio por kWp"]) : formatCurrency(Math.round(cash_data["Precio por kWp"]));

  // const taxBenefits = cashflowInfo[2][11] * 1000;
  // const taxBenefitsRatio = ((taxBenefits / cash_data.subtotal) * 100).toFixed(
  //   2
  // );

  const ivaRecovery = cashflowInfo.reduce((acumulador, subarreglo) => (acumulador + subarreglo[10]), 0) * 1000;
  const _taxBenefits = cashflowInfo.reduce((acumulador, subarreglo) => (acumulador + subarreglo[11]), 0) * 1000;
  const depreciation = cashflowInfo.reduce((acumulador, subarreglo) => (acumulador + subarreglo[12]), 0) * 1000;

  const total = _taxBenefits + ivaRecovery + depreciation;
  const ratio = ((total / cash_data.total) * 100).toFixed(2);

  const iconCoins = (
    <Svg style={styles.icon} width="450" height="auto" viewBox="0 0 512 512">
      <Path
        id="coins-solid"
        d="M0,405.3V448c0,35.3,86,64,192,64s192-28.7,192-64V405.3C342.7,434.4,267.2,448,192,448S41.3,434.4,0,405.3ZM320,128c106,0,192-28.7,192-64S426,0,320,0,128,28.7,128,64,214,128,320,128ZM0,300.4V352c0,35.3,86,64,192,64s192-28.7,192-64V300.4c-41.3,34-116.9,51.6-192,51.6S41.3,334.4,0,300.4Zm416,11c57.3-11.1,96-31.7,96-55.4V213.3c-23.2,16.4-57.3,27.6-96,34.5ZM192,160C86,160,0,195.8,0,240s86,80,192,80,192-35.8,192-80S298,160,192,160Zm219.3,56.3c60-10.8,100.7-32,100.7-56.3V117.3c-35.5,25.1-96.5,38.6-160.7,41.8C380.8,173.4,402.5,192.6,411.3,216.3Z"
        fill="#fff"
        opacity="0.15"
      />
    </Svg>
  );

  return (
    <>
      {isEpm && (
        <View style={styles.empIconContentAbsolute}>
          <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
        </View>
      )}
      <View style={styles.containerInvestment}>
        <Text style={styles.titleLeft}>Costo de Inversión</Text>
        <View style={styles.tableHeaderInv}>
          <Text style={styles.itemInvestmentI}>Ítem</Text>
          <Text style={styles.itemInvestmentDescription}>Descripción</Text>
          <Text style={styles.itemInvestmentAmount}>Cantidad</Text>
          <Text style={styles.itemInvestment}>Total</Text>
        </View>
        {tableData.map((row, index) => (
          <View
            key={index}
            style={
              (index + 1) % 2 === 0
                ? styles.tableRowInvEven
                : styles.tableRowInv
            }
          >
            <Text style={styles.itemInvestmentI}>{index + 1}</Text>
            <Text style={styles.itemInvestmentDescription}>
              {row["Descripción"]}
            </Text>
            <Text style={styles.itemInvestmentAmount}>{row["Cantidad"]}</Text>
            <Text style={styles.itemInvestment}>
              {isEpm && row["Total"] === "$ 0"
                ? formatCurrency(investmentCost.subtotal)
                : formatCurrency(row["Total"])}
            </Text>
          </View>
        ))}
        <View style={styles.contentTotal}>
          <Text style={styles.spaceBlank}> </Text>
          <View style={styles.penultimate}>
            <Text>Subtotal</Text>
            {city.includes("panama") ? (
              <>
                <Text style={styles.itemSpaceIva}>ITBMS</Text>
              </>
            ) : (
              <>
                <Text style={styles.itemSpaceIva}>IVA</Text>
              </>
            )}
          </View>
          <View style={styles.ultimate}>
            <Text>
              {formatCurrency(Math.round(investmentCost.subtotal))}
            </Text>
            <Text style={styles.itemSpaceIva}>
              {formatCurrency(Math.round(investmentCost.iva))}
            </Text>
          </View>
        </View>
        <View style={styles.costInvestmentDescription}>
          <View style={styles.columnInvestmentGreen}>
            {iconCoins}
            <Text>{value}</Text>
            <Text>Precio por Wp</Text>
          </View>
          <View style={styles.columnInvestmentYellow}>
            {iconCoins}
            <Text>{formatCurrency(Math.round(investmentCost.total))}</Text>
            <Text>Total Proyecto IVA Incluido</Text>
          </View>
        </View>
        {allowedROI && !isPanama && (
          <>
            <View style={styles.costInvestmentDescription}>
              <View style={styles.columnInvestmentGreenLight}>
                {iconCoins}
                <Text>{formatCurrency(Math.round(total))}</Text>
                <Text>Beneficios tributarios</Text>
              </View>
              <View style={styles.columnInvestmentGrey}>
                {iconCoins}
                <Text>{formatNumber(ratio)}%</Text>
                <Text className="text-justify">
                  Beneficios tributarios / Total
                </Text>
              </View>
            </View>
          </>
        )}
      </View>
    </>
  );
};

export default InvestmentPdf;
