import {
  CREATE_VARIABLE_DATA_TYPE,
  UPDATE_VARIABLE_DATA_TYPE
} from "./variablesDataTypes";

const initialState = {
  data: [{ year: 15, price: 5.2, discount: 10, excess: 20, paymentTax: 6.78 }]
};

const variablesDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_VARIABLE_DATA_TYPE:
      return {
        ...state,
        data: [...state.data, action.payload]
      };
    case UPDATE_VARIABLE_DATA_TYPE:
      const newData = {
        data: [action.payload]
      };
      return newData;
    default:
      return state;
  }
};

export default variablesDataReducer;
