import { FETCH_GLOBAL_DATA_SUCCESS, FETCH_GLOBAL_DATA_FAILURE, UPDATE_PROJECT_TOTAL_COST } from './globalDataTypes';
import initialData from '../../handlers/data.json';

const initialState = {
    data: initialData,
    error: ''
}

const globalDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_GLOBAL_DATA_SUCCESS:
            return {
                data: action.payload,
                error: ''
            }
        case FETCH_GLOBAL_DATA_FAILURE:
            return {
                data: [],
                error: ''
            }
        case UPDATE_PROJECT_TOTAL_COST:
            const newState = {...state}
            newState.data['costo de inversion'].subtotal = action.payload.subtotal
            newState.data['costo de inversion'].iva = action.payload.IVA
            newState.data['costo de inversion'].total = action.payload.totalCost
            return newState
            default: return state
    }
}

export default globalDataReducer;