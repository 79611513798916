import React from "react";

import { Text, View, Image, Svg, Path, G } from "@react-pdf/renderer";

import { styles } from "../../../utils/handlers/stylesPDF";
import { epmLogo, formatNumber } from "../../../utils/handlers/handlers";

const CalculationDataPdf = ({ data, isEpm }) => {
  const source = data["datos de calculo"];
  const cardsData = source;
  const table_data = data["cotizacion estandar"];
  const solar_energy = source["grafica"][1];
  const system_index = table_data.findIndex((data) =>
    data["Descripción"].includes("Sistema Solar")
  );
  const tableData = table_data.slice(0, system_index);
  const solarEnergy = (solar_energy.value).toFixed(1);
  // const numberFormat = Intl.NumberFormat("de-DE");

  const powerSystem = cardsData.datos[0];
  const energyProduction = cardsData.datos[1];
  const currentConsumption = cardsData.datos[2];
  const area = cardsData.datos[3];

  const sun = (
    <Svg
      style={styles.iconCalculation}
      width="511.975"
      height="auto"
      viewBox="0 0 511.975 512"
    >
      <Path
        id="sun-solid"
        d="M256,160a96,96,0,1,0,96,96A96.153,96.153,0,0,0,256,160Zm246.4,80.5-94.7-47.3L441.2,92.8c4.5-13.6-8.4-26.5-21.9-21.9L318.9,104.4,271.5,9.6a17.313,17.313,0,0,0-31,0l-47.3,94.7L92.7,70.8c-13.6-4.5-26.5,8.4-21.9,21.9l33.5,100.4L9.6,240.5a17.313,17.313,0,0,0,0,31l94.7,47.3L70.8,419.3c-4.5,13.6,8.4,26.5,21.9,21.9l100.4-33.5,47.3,94.7a17.313,17.313,0,0,0,31,0l47.3-94.7,100.4,33.5c13.6,4.5,26.5-8.4,21.9-21.9L407.5,318.9l94.7-47.3a17.334,17.334,0,0,0,.2-31.1Zm-155.9,106a127.986,127.986,0,1,1,0-181A128.135,128.135,0,0,1,346.5,346.5Z"
        fill="#fff"
        opacity="0.15"
      />
    </Svg>
  );

  const solar = (
    <Svg
      style={styles.iconCalculation}
      width="190"
      height="auto"
      viewBox="0 0 180.542 144.323"
    >
      <G id="layer1" transform="translate(-4.312 -132.103)" opacity="0.15">
        <Path
          id="path24"
          d="M61.119,276.016c-2.384-1.243-2.514-1.684-2.514-8.527,0-5.574.023-5.828.6-6.67a7.079,7.079,0,0,1,1.342-1.428c.711-.527,1.113-.557,8.4-.631l7.654-.078v-9.238H112.58v9.26h7.438c7.086,0,7.486.026,8.435.56a4.886,4.886,0,0,1,1.571,1.582c.549.978.571,1.279.494,6.914-.078,5.734-.1,5.916-.716,6.783a5.047,5.047,0,0,1-1.72,1.383c-1.039.472-2.461.493-33.628.5-29,0-32.63-.041-33.337-.409ZM10.422,239.8a9.1,9.1,0,0,1-5.969-6.874c-.319-1.541-.247-2.1,1.891-14.831,8.487-50.532,13.451-79.178,13.949-80.51a10.3,10.3,0,0,1,4.631-4.888l1.271-.592h136.79l1.271.592a10.292,10.292,0,0,1,4.635,4.9c.341.912,1.932,9.573,4.032,21.952,1.908,11.245,5.371,31.638,7.7,45.317,2.552,15.006,4.232,25.588,4.236,26.68a8.709,8.709,0,0,1-5.006,7.74l-1.52.77-83.476.048c-68.936.04-83.642-.013-84.431-.3Zm46.067-17.912c0-.639,2.811-28.546,3.053-30.317l.154-1.124H29.539l-.276,1.389c-.433,2.178-5.053,29.839-5.053,30.254,0,.315,2.463.371,16.14.371h16.14Zm62.586-.023c-.072-.327-.792-7.263-1.6-15.412s-1.528-15.085-1.6-15.414l-.13-.6-21.219.068-21.219.068L71.8,205.525c-.829,8.222-1.566,15.4-1.636,15.941l-.128.992H119.2Zm45.894.322c0-.368-4.7-28.594-5.05-30.352l-.28-1.389H129.481l.154,1.124c.227,1.665,3.054,29.782,3.054,30.379,0,.5.363.511,16.14.511,10.936,0,16.14-.088,16.14-.274ZM61.107,176.09c.084-.618.686-6.482,1.337-13.031s1.241-12.234,1.311-12.634l.128-.728H50.132c-7.563,0-13.752.053-13.752.117s-1.012,6.091-2.249,13.393-2.249,13.441-2.249,13.642c0,.307,2.343.366,14.536.366H60.954Zm53.254,0c-.079-.618-.714-6.81-1.412-13.758L111.681,149.7H77.391l0,.6c0,.327-.6,6.37-1.321,13.428s-1.32,12.981-1.321,13.163c0,.262,4.149.331,19.878.331H114.5l-.143-1.124Zm42.934.808c0-.174-1.012-6.3-2.249-13.612s-2.249-13.361-2.249-13.442-6.188-.146-13.752-.146H125.293l.127.728c.07.4.663,6.145,1.319,12.766s1.261,12.485,1.345,13.031l.153.992h14.529c11.1,0,14.529-.075,14.529-.317Z"
          fill="#fff"
        />
      </G>
    </Svg>
  );

  const bolt = (
    <Svg
      style={styles.iconCalculation}
      width="60"
      height="auto"
      viewBox="0 0 90.101 144.37"
    >
      <G id="layer1" transform="translate(-57.062 -100.679)" opacity="0.15">
        <Path
          id="path24"
          d="M88.51,244.723a6.886,6.886,0,0,1-4.411-6.157c0-.671,12.232-53.017,13.131-56.193.12-.425-.993-.459-17.547-.529l-17.676-.075-1.284-.656a6.637,6.637,0,0,1-3.658-6.091c.055-1.99,8.97-68.618,9.352-69.892A7.065,7.065,0,0,1,69.93,101.3l1.338-.623,21.828,0c20.556,0,21.89.031,22.886.486a6.3,6.3,0,0,1,3.942,6.121c-.016,1.508-.766,3.986-6.092,20.108l-6.075,18.388,17.342.132,17.342.132,1.388.769a6.556,6.556,0,0,1,3.227,6.852c-.188.994-4.046,7.862-16.356,29.119-8.86,15.3-20.244,34.962-25.3,43.693s-9.444,16.154-9.755,16.5a7.029,7.029,0,0,1-7.135,1.744Z"
          fill="#fff"
        />
      </G>
    </Svg>
  );

  const house = (
    <Svg
      style={styles.iconCalculation}
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="auto"
      viewBox="0 0 180.902 144.448"
    >
      <G id="layer1" transform="translate(-77.427 -177.06)" opacity="0.15">
        <Path
          id="path24"
          d="M78.322,320.875a3.384,3.384,0,0,1-.794-1.521c-.089-.489-.127-22.976-.084-49.97l.078-49.08.617-1.71a14.617,14.617,0,0,1,5.541-7.38c1.474-.946,78.866-33.226,81.012-33.79a14.132,14.132,0,0,1,6.371,0c2.145.564,79.537,32.844,81.012,33.79a14.617,14.617,0,0,1,5.541,7.38l.617,1.71.078,49.08c.043,26.994.005,49.48-.084,49.97a3.384,3.384,0,0,1-.794,1.521l-.632.632H245c-12.421,0-13.02-.053-13.461-1.2-.09-.236-.166-16.522-.167-36.192,0-37.49.01-37.044-1.126-39.2a12.647,12.647,0,0,0-4.039-3.785,12.082,12.082,0,0,0-2.628-.821c-2.2-.407-109.207-.407-111.408,0a12.081,12.081,0,0,0-2.628.821,12.647,12.647,0,0,0-4.039,3.785c-1.135,2.159-1.122,1.713-1.126,39.2,0,19.67-.077,35.956-.167,36.192-.441,1.149-1.04,1.2-13.461,1.2h-11.8Zm36.455.315a2.113,2.113,0,0,1-.86-1.08c-.353-1.013-.373-13.49-.024-14.708.5-1.734-3.841-1.6,53.936-1.614,33.91-.005,52.908.084,53.22.251a2.255,2.255,0,0,1,.776,1.1c.384,1.1.4,13.875.015,14.971a2.122,2.122,0,0,1-.938,1.1c-.907.413-105.269.4-106.124-.016Zm.545-26.959c-1.481-.429-1.575-1.044-1.422-9.294.121-6.578.184-7.382.608-7.805.45-.449,3.007-.475,53.049-.541,37.968-.05,52.774.012,53.287.223a1.966,1.966,0,0,1,.992,1.1,55.843,55.843,0,0,1,.282,7.661c0,7.607-.1,8.119-1.619,8.65-1.057.369-103.9.374-105.176.005Zm-.229-27.269c-1.174-.642-1.191-.766-1.191-8.695v-7.576l.683-.641.683-.641,51.771-.077c28.474-.043,52.284-.005,52.911.085a1.854,1.854,0,0,1,1.653,1c.473.776.513,1.38.514,7.747a55.621,55.621,0,0,1-.283,7.72,1.7,1.7,0,0,1-1.1,1.1c-1.162.405-104.9.384-105.642-.022Z"
          fill="#fff"
        />
      </G>
    </Svg>
  );

  return (
    <>
    { isEpm &&
      <View style={styles.empIconContentAbsolute}>
        <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
      </View>
    }
    <View style={{ marginTop: 90 }}>
      <Text style={styles.titleLeft}>Tu Sistema Solar</Text>
      <View style={styles.containerCalculation}>
        <View style={styles.calculationLeft}>
          <View style={styles.tableHeaderCalculation}>
            <Text style={styles.itemCalculation}>Ítem</Text>
            <Text style={styles.itemCalculationDescription}>Descripción</Text>
            <Text style={styles.itemCalculationAmount}>Cantidad</Text>
          </View>
          {tableData.map((data, index) => (
            <View
              key={index}
              style={
                (index + 1) % 2 === 0
                  ? styles.tableRowInvEven
                  : styles.tableRowInv
              }
            >
              <Text style={styles.itemCalculation}>{index + 1}</Text>
              <Text style={styles.itemCalculationDescription}>
                {data["Descripción"]}
              </Text>
              <Text style={styles.itemCalculationAmount}>
                {data["Cantidad"]}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.CalculationRigth}>
          <View style={styles.calculationRow}>
            <View style={styles.solarEnergy}>
              <View style={styles.calculationRow}>
                <Text style={styles.solarText}>Convencional</Text>
                <Text style={styles.calculationYellow}>
                  {solarEnergy > 100 ? 0 : ((solarEnergy - 100) * -1).toFixed(1)}%
                </Text>
              </View>
              <View style={styles.calculationRow}>
                <Text style={styles.solarText}>Solar</Text>
                <Text style={styles.calculationGreen}>{solarEnergy > 100 ? 100 : solarEnergy}%</Text>
              </View>
            </View>

            <View style={styles.calculationSolar}>
              <View>
                <Svg width="50" height="auto" viewBox="0 0 180.542 144.323">
                  <G
                    id="layer1"
                    transform="translate(-4.312 -132.103)"
                    opacity="0.5"
                  >
                    <Path
                      id="path24"
                      d="M61.119,276.016c-2.384-1.243-2.514-1.684-2.514-8.527,0-5.574.023-5.828.6-6.67a7.079,7.079,0,0,1,1.342-1.428c.711-.527,1.113-.557,8.4-.631l7.654-.078v-9.238H112.58v9.26h7.438c7.086,0,7.486.026,8.435.56a4.886,4.886,0,0,1,1.571,1.582c.549.978.571,1.279.494,6.914-.078,5.734-.1,5.916-.716,6.783a5.047,5.047,0,0,1-1.72,1.383c-1.039.472-2.461.493-33.628.5-29,0-32.63-.041-33.337-.409ZM10.422,239.8a9.1,9.1,0,0,1-5.969-6.874c-.319-1.541-.247-2.1,1.891-14.831,8.487-50.532,13.451-79.178,13.949-80.51a10.3,10.3,0,0,1,4.631-4.888l1.271-.592h136.79l1.271.592a10.292,10.292,0,0,1,4.635,4.9c.341.912,1.932,9.573,4.032,21.952,1.908,11.245,5.371,31.638,7.7,45.317,2.552,15.006,4.232,25.588,4.236,26.68a8.709,8.709,0,0,1-5.006,7.74l-1.52.77-83.476.048c-68.936.04-83.642-.013-84.431-.3Zm46.067-17.912c0-.639,2.811-28.546,3.053-30.317l.154-1.124H29.539l-.276,1.389c-.433,2.178-5.053,29.839-5.053,30.254,0,.315,2.463.371,16.14.371h16.14Zm62.586-.023c-.072-.327-.792-7.263-1.6-15.412s-1.528-15.085-1.6-15.414l-.13-.6-21.219.068-21.219.068L71.8,205.525c-.829,8.222-1.566,15.4-1.636,15.941l-.128.992H119.2Zm45.894.322c0-.368-4.7-28.594-5.05-30.352l-.28-1.389H129.481l.154,1.124c.227,1.665,3.054,29.782,3.054,30.379,0,.5.363.511,16.14.511,10.936,0,16.14-.088,16.14-.274ZM61.107,176.09c.084-.618.686-6.482,1.337-13.031s1.241-12.234,1.311-12.634l.128-.728H50.132c-7.563,0-13.752.053-13.752.117s-1.012,6.091-2.249,13.393-2.249,13.441-2.249,13.642c0,.307,2.343.366,14.536.366H60.954Zm53.254,0c-.079-.618-.714-6.81-1.412-13.758L111.681,149.7H77.391l0,.6c0,.327-.6,6.37-1.321,13.428s-1.32,12.981-1.321,13.163c0,.262,4.149.331,19.878.331H114.5l-.143-1.124Zm42.934.808c0-.174-1.012-6.3-2.249-13.612s-2.249-13.361-2.249-13.442-6.188-.146-13.752-.146H125.293l.127.728c.07.4.663,6.145,1.319,12.766s1.261,12.485,1.345,13.031l.153.992h14.529c11.1,0,14.529-.075,14.529-.317Z"
                      fill="#fff"
                    />
                  </G>
                </Svg>
              </View>
              <View>
                <Text style={styles.calculationValue}>
                  {cardsData.numPaneles}
                </Text>
                <Text style={styles.units}>Paneles</Text>
              </View>
            </View>
          </View>

          <View style={styles.calculationRow}>
            <View style={styles.cardCalculationGreen}>
              {sun}
              <Text style={styles.calculationValue}>
                {formatNumber((Number(powerSystem.value * 10) / 10).toFixed(2))}{" "}
                <Text style={styles.units}>{powerSystem.units}</Text>
              </Text>
              <Text>{powerSystem.name}</Text>
            </View>
            <View style={styles.cardCalculationGreenSecond}>
              {solar}
              <Text style={styles.calculationValue}>
                {formatNumber(Math.floor(energyProduction.value))}{" "}
                <Text style={styles.units}>{energyProduction.units} </Text>
              </Text>
              <Text>{energyProduction.name}</Text>
            </View>
          </View>
          <View style={styles.calculationRow}>
            <View style={styles.cardCalculationYellow}>
              {bolt}
              <Text style={styles.calculationValue}>
                {formatNumber(currentConsumption.value)}{" "}
                <Text style={styles.units}> {currentConsumption.units} </Text>
              </Text>
              <Text>{currentConsumption.name}</Text>
            </View>
            <View style={styles.cardCalculationGrey}>
              {house}
              <Text style={styles.calculationValue}>
                {formatNumber(Math.ceil(area.value))} <Text style={styles.units}>{area.units}</Text>
              </Text>
              <Text>{area.name}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    </>
  );
};

export default CalculationDataPdf;
