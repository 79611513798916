import { combineReducers } from 'redux';
import globalDataReducer from './globalData/globalDataReducer';
import adviserDataReducer from './adviserData/adviserDataReducer';
import chartsDataReducer from './chartsData/chartsDataReducer';
import filesDataReducer from './filesData/filesDataReducer';
import contractDataReducer from './contractData/contractDataReducer';
import versionsDataReducer from './versionsData/versionsDataReducer';
import variablesDataReducer from './variablesData/variablesDataReducer';
import typeOperationReducer from './typeOperation/typeOperationReducer';


const rootReducer = combineReducers({
    globalData: globalDataReducer,
    adviserData: adviserDataReducer,
    chartsData: chartsDataReducer,
    filesData: filesDataReducer,
    contractData: contractDataReducer,
    versionsData: versionsDataReducer,
    variableData: variablesDataReducer,
    typeOperation: typeOperationReducer
});

export default rootReducer;