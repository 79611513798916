import React from "react";

import { Text, View, Svg, Path, Link, Image } from "@react-pdf/renderer";

import { styles } from "../../../utils/handlers/stylesPDF";
import { epmLogo, formatCurrency, formatNumber } from "../../../utils/handlers/handlers";
// import { returnData } from "../../../utils/handlers/handlers";

const ReturnInvestmentPdf = ({ data, state, id, version, isEpm, city }) => {
  const variableData = state.variableData; //Slider Data
  const cardsInfo = state.chartsData.data.cardsInfo;
  const isPanama = city.includes("panama");

  // const numberFormat = Intl.NumberFormat("de-DE");

  const year = cardsInfo[0].value;
  const tir = cardsInfo[1].value;
  const vpn = (cardsInfo[2].value * 1000) / 1000000;
  const value = formatCurrency(Math.round(vpn));
  const datoVpn = !isPanama ? `${value}M COP` : `${value}K USD`;

  const calendar = (
    <Svg style={styles.icon} width="449" height="513" viewBox="0 0 449 513">
      <Path
        id="calendar-alt-solid"
        d="M0,464a48.012,48.012,0,0,0,48,48H400a48.012,48.012,0,0,0,48-48V192H0ZM320,268a12.035,12.035,0,0,1,12-12h40a12.035,12.035,0,0,1,12,12v40a12.035,12.035,0,0,1-12,12H332a12.035,12.035,0,0,1-12-12Zm0,128a12.035,12.035,0,0,1,12-12h40a12.035,12.035,0,0,1,12,12v40a12.035,12.035,0,0,1-12,12H332a12.035,12.035,0,0,1-12-12ZM192,268a12.035,12.035,0,0,1,12-12h40a12.035,12.035,0,0,1,12,12v40a12.035,12.035,0,0,1-12,12H204a12.035,12.035,0,0,1-12-12Zm0,128a12.035,12.035,0,0,1,12-12h40a12.035,12.035,0,0,1,12,12v40a12.035,12.035,0,0,1-12,12H204a12.035,12.035,0,0,1-12-12ZM64,268a12.035,12.035,0,0,1,12-12h40a12.035,12.035,0,0,1,12,12v40a12.035,12.035,0,0,1-12,12H76a12.035,12.035,0,0,1-12-12Zm0,128a12.035,12.035,0,0,1,12-12h40a12.035,12.035,0,0,1,12,12v40a12.035,12.035,0,0,1-12,12H76a12.035,12.035,0,0,1-12-12ZM400,64H352V16A16.047,16.047,0,0,0,336,0H304a16.047,16.047,0,0,0-16,16V64H160V16A16.047,16.047,0,0,0,144,0H112A16.047,16.047,0,0,0,96,16V64H48A48.012,48.012,0,0,0,0,112v48H448V112A48.012,48.012,0,0,0,400,64Z"
        transform="translate(0.5 0.5)"
        fill="#fff"
        stroke="#fff"
        stroke-width="1"
        opacity="0.15"
      />
    </Svg>
  );

  const hand = (
    <Svg
      style={styles.icon}
      width="576.012"
      height="512"
      viewBox="0 0 576.012 512"
    >
      <Path
        id="hand-holding-usd-solid"
        d="M271.06,144.3l54.27,14.3a8.59,8.59,0,0,1,6.63,8.1c0,4.6-4.09,8.4-9.12,8.4h-35.6a30,30,0,0,1-11.19-2.2c-5.24-2.2-11.28-1.7-15.3,2l-19,17.5a11.68,11.68,0,0,0-2.25,2.66,11.42,11.42,0,0,0,3.88,15.74,83.77,83.77,0,0,0,34.51,11.5V240c0,8.8,7.83,16,17.37,16h17.37c9.55,0,17.38-7.2,17.38-16V222.4c32.93-3.6,57.84-31,53.5-63-3.15-23-22.46-41.3-46.56-47.7L282.68,97.4a8.59,8.59,0,0,1-6.63-8.1c0-4.6,4.09-8.4,9.12-8.4h35.6A30,30,0,0,1,332,83.1c5.23,2.2,11.28,1.7,15.3-2l19-17.5a11.309,11.309,0,0,0,2.17-2.6,11.43,11.43,0,0,0-3.84-15.78,83.82,83.82,0,0,0-34.52-11.5V16c0-8.8-7.82-16-17.37-16H295.37C285.82,0,278,7.2,278,16V33.6c-32.89,3.6-57.85,31-53.51,63C227.63,119.6,247,137.9,271.06,144.3ZM565.27,328.1c-11.8-10.7-30.2-10-42.6,0L430.27,402a63.64,63.64,0,0,1-40,14H272a16,16,0,0,1,0-32h78.29c15.9,0,30.71-10.9,33.25-26.6a31.2,31.2,0,0,0,.46-5.46A32,32,0,0,0,352,320H192a117.66,117.66,0,0,0-74.1,26.29L71.4,384H16A16,16,0,0,0,0,400v96a16,16,0,0,0,16,16H372.77a64,64,0,0,0,40-14L564,377a32,32,0,0,0,1.28-48.9Z"
        fill="#fff"
        opacity="0.15"
      />
    </Svg>
  );

  const bill = (
    <Svg
      style={styles.icon}
      width="641.01"
      height="449"
      viewBox="0 0 641.01 449"
    >
      <Path
        id="money-bill-wave-solid"
        d="M621.16,54.46C582.37,38.19,543.55,32,504.75,32c-123.17-.01-246.33,62.34-369.5,62.34-30.89,0-61.76-3.92-92.65-13.72A34.192,34.192,0,0,0,32.25,79C15.04,79,0,92.32,0,110.81V428.07c0,12.63,7.23,24.6,18.84,29.46C57.63,473.81,96.45,480,135.25,480c123.17,0,246.34-62.35,369.51-62.35,30.89,0,61.76,3.92,92.65,13.72a34.192,34.192,0,0,0,10.35,1.62c17.21,0,32.25-13.32,32.25-31.81V83.93c-.01-12.64-7.24-24.6-18.85-29.47ZM48,132.22c20.12,5.04,41.12,7.57,62.72,8.93A64.074,64.074,0,0,1,48,192.69Zm0,285V369.44c34.37,0,62.18,27.27,63.71,61.4A241.579,241.579,0,0,1,48,417.22ZM320,352c-44.19,0-80-42.99-80-96s35.82-96,80-96,80,42.98,80,96S364.17,352,320,352Zm272,27.78c-17.52-4.39-35.71-6.85-54.32-8.44A63.865,63.865,0,0,1,592,322.06Zm0-236.11a63.872,63.872,0,0,1-55.81-61.55A240.382,240.382,0,0,1,592,94.78Z"
        transform="translate(0.5 -31.5)"
        fill="#fff"
        stroke="#fff"
        stroke-width="1"
        opacity="0.15"
      />
    </Svg>
  );

  return (
    <>
      {isEpm &&
        <View style={styles.empIconContentAbsolute}>
          <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
        </View>
      }
      <View style={styles.containerReturnInvestment}>
        <Text style={styles.titleLeft}>Retorno de Inversión</Text>
        <View style={styles.cardContainer}>
          <View style={styles.cardContainerGreenSecond}>
            {calendar}
            <Text style={styles.variableText}>{year}</Text>
            <Text>Año de retorno</Text>
          </View>
          <View style={styles.cardContainerGreen}>
            {hand}
            <Text style={styles.variableText}>{formatNumber(tir)} %</Text>
            <Text>TIR</Text>
          </View>
          <View style={styles.cardContainerYellow}>
            {bill}
            <Text style={styles.variableText}>{datoVpn}</Text>
            <Text>VPN</Text>
          </View>
        </View>
        <View style={styles.contentVar}>
          <View style={styles.viewRow}>
            <Text style={styles.generalText}>Horizonte del proyecto:</Text>
            <Text style={styles.greenText}>{variableData.data[0].year} años</Text>
          </View>
          <View style={styles.viewRow}>
            <Text style={styles.generalText}>Crecimiento precio kWh:</Text>
            <Text style={styles.greenText}>{variableData.data[0].price}%</Text>
          </View>
          <View style={styles.viewRow}>
            <Text style={styles.generalText}>
              Tasa de descuento / Oportunidad:
            </Text>
            <Text style={styles.greenText}>{variableData.data[0].discount}%</Text>
          </View>
        {
          !isPanama && <View style={styles.viewRow}>
            <Text style={styles.generalText}>Excedentes:</Text>
            <Text style={styles.greenText}>{variableData.data[0].excess}%</Text>
          </View>
        }   
        </View>
        {!isEpm &&
          <Link
            src={`https://quote.ercoenergia.com.co/${id}/${version}#returnInvestment`}
            style={styles.link}
          >
            Ver Web Análisis Financiero
          </Link>
        }
        {isEpm &&
          <View style={{...styles.textContent, paddingTop:'20px'}}>
            <Text style={styles.point}>*</Text>
            <Text style={styles.text}>Las cifras aquí presentadas son de referencia, basadas en proyecciones y modelos estadísticos. No constituyen una garantía por parte de EPM.</Text>
          </View>
        }
        {!isPanama && !isEpm &&
          <View style={{...styles.textContent, paddingTop:'20px'}}>
            <Text style={styles.point}>*</Text>
            <Text style={styles.text}>Las cifras aquí presentadas son de referencia, basadas en proyecciones y modelos estadísticos. No constituyen una garantía por parte de Erco.</Text>
          </View>
        }
      </View>
    </>
  );
};

export default ReturnInvestmentPdf;
