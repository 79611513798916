import {
    FETCH_CHARTS_DATA_SUCCESS,
    FETCH_CHARTS_DATA_FAILURE
} from './chartsDataTypes';
import { SETData } from '../../handlers/WebServices';

// Convetional Chart Data
export const fetchChartsDataSuccess = (data) => {
    return {
        type: FETCH_CHARTS_DATA_SUCCESS,
        payload: data
    }
}

export const fetchChartsDataFailure = (error) => {
    return {
        type: FETCH_CHARTS_DATA_FAILURE,
        payload: error
    }
}

export const fetchChartsData = (calculation_data, incremento_costo_energia, tasa_oportunidad, horizonte, subtotal, iva, excess, quoteType, city) => {
    const requestData = {
        calculation_data,
        incremento_costo_energia,
        tasa_oportunidad,
        horizonte,
        subtotal,
        iva,
        "excedentes": excess,
        quoteType,
        city
    };
    
    return (dispatch) => {
        SETData(requestData, 'cashflow')
        .then(response => {
            const data = response.data;
            dispatch(fetchChartsDataSuccess(data));
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchChartsDataFailure(error.message))
        })
    }

}

// Leasing Chart Data
export const fetchLeasingChartsData = (tasa_oportunidad, subtotal, iva, excess, tax, energyCostCol, yearEnergy, maintenanceCost, year, calculationData, energy_cost_increase) => {
    const requestData = {
        tax: 35,
        energyCostCol,
        yearEnergy,
        "excessFare": excess,
        "maintenanceCost": maintenanceCost,
        "investmentCost": subtotal + iva,
        "paymentTax": tax,
        iva,
        tasa_oportunidad,
        year,
        calculationData, 
        energy_cost_increase
        // incremento_costo_OM,
        // potencia_KWP,
        // potencia_AC
    }
    
    return (dispatch) => {
        SETData(requestData, 'get-leasing-information')
        .then(response => {
            const data = response.data;
            dispatch(fetchChartsDataSuccess(data));
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchChartsDataFailure(error.message))
        })
    }
}