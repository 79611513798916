import React from 'react';

export default function SignatureFile(props) {

    const getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            
            reader.readAsDataURL(file);
            reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
            };
        });
    };

    const handleChange = (e) => {    
        let file = e.target.files[0];
        getBase64(file)
          .then(result => {
            props.setSignature(result);
        });
    }

    return (
        <div className="w-full mt-10 mx-auto flex flex-col justify-center">
            <div className="w-2/3 mx-auto">
                <h1>Adjuntar archivo local:</h1>
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    className="w-60"
                    onChange={(e) => handleChange(e)}
                />
            </div>
        </div>
    )
}