import { CHANGED_LEASING } from "./typeOperationType";

export const changedLeasingSucces = () => {
    return {
      type: CHANGED_LEASING
    };
  };
  
  export const changedLeasing = () => {
    return (dispatch) => {
      dispatch(changedLeasingSucces());
    };
  };
  