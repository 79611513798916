import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

// import { ClassNames } from "../utils/ClassNames";

export default function ToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const toTheTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className="fixed bottom-4 md:bottom-8 right-2 flex justify-between px-3 md:px-10">
          <button
            type="button"
            onClick={toTheTop}
            className="bg-font-green cursor-pointer h-14 w-14 lg:h-16 lg:w-16 rounded-lg shadow-2xl"
          >
            <div className="w-full h-full flex justify-center items-center">
              <FontAwesomeIcon
                className="h-1/2 w-1/2"
                icon={faArrowUp}
                color="white"
                size="2x"
              />
            </div>
          </button>
        </div>
      )}
    </>
  );
}
