import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DataCard(props) {

    return (
        <div key={props.key} className={"data-card " + props.containerClass} style={props.style}>
          <div className={props.valuesClass}>
              <h1 className="my-auto">
                {props.value}
                <span className="ml-1">
                  {
                    props.units ? props.units : "" 
                  }
                </span>
              </h1>
              <h4 className="mb-3 2xl:mb-5">{props.name}</h4>
          </div>
          <div style={props.iconStyle}>
            <FontAwesomeIcon
              opacity="15%"
              icon={props.item.icon}
              color="white"
              size={props.iconSize}
            />
          </div>
        </div>
    )
}
