import React from "react";
import { connect } from "react-redux";

// Utils
import { formatNumber, investmentData } from "../utils/handlers/handlers";

// Partial components
import Table from "./PartialComponents/Table";
import PanelCard from "./PartialComponents/PanelCard";
import PieChart from "./PartialComponents/PieChart";
import DataCard from "./PartialComponents/DataCard";

function DatosCalculo({ table_data, cards_data, solar_energy }) {
    const columns = ["Descripción", "Cantidad"];
    const system_index = table_data.findIndex((data) => data["Descripción"].includes('Sistema Solar'))
    const tableData = table_data.slice(0, system_index);
    const cardsData = cards_data;
    const solarEnergy = solar_energy.value > 100 ? 100 : solar_energy.value;
    // const solarEnergy = solar_energy.value;
    const numberFormat = Intl.NumberFormat("de-DE")

    return (
      <section
      id="calculationData"
      className="calculationData flex lg:h-screen lg:w-screen h-auto mb-10 lg:mb-0 justify-center lg:pt-12"
      >
        <div className="flex flex-col lg:flex-row items-center mx-11">
            <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-2/5 mr-2">
                    <div className ="table-container w-screen lg:w-full lg:overflow-y-scroll mx-auto overflow-x-scroll lg:overflow-x-hidden overscroll-auto lg:overscroll-contain"> 
                        <Table columns={columns} data={tableData} id="calculation-data-table" sizes={['30%', '40%', '30%']}/>
                    </div>
                </div>
                <div className="w-full mx-auto lg:w-3/5">
                    {/* PANEL CARD */}
                    <div className="lg:grid lg:grid-cols-2 lg:gap-6 2xl:h-80">
                        <div className="h-72 w-full">
                            <PieChart />
                            <h1 className="solar-energy text-center relative lg:-top-52 mt-1 ml-2">
                                {`${Math.round(solarEnergy)}%`}
                            </h1>
                        </div>
                        <div className="2xl:h-72 lg:w-auto lg:mx-0">
                            <PanelCard panels={cardsData.numPaneles} areaPanel={cardsData?.panelArea} />
                        </div>
                    </div>

                    {/* DATA CARDS */}
                    <div className="h-auto w-5/6 mx-auto lg:ml-0 lg:w-auto mt-4 lg:mt-1 grid lg:grid-cols-2 lg:gap-6 gap-4">
                        {
                            investmentData.map((item, index) => {
                                const style = {
                                    backgroundColor: `${item.bgColor}`
                                }

                                const iconStyle = {
                                    fontSize: '1.5rem',
                                    position: 'relative',
                                    top: '-7.9rem'
                                }

                                let value = cardsData.datos[index].value;
                                const name = cardsData.datos[index].name;
                                const units = cardsData.datos[index].units;

                                switch (name) {
                                    case 'Área mínima Requerida':
                                        value = formatNumber(Math.ceil(value));
                                        break;
                                    case 'Producción de Energía':
                                        value = formatNumber(Math.floor(value));
                                        break;
                                    case 'Potencia del Sistema':
                                        value = formatNumber(((value * 10) / 10).toFixed(2));
                                        break;
                                    default:
                                        value = formatNumber(value)
                                        break;
                                }

                                return (
                                    <DataCard
                                        key={index}
                                        style={style}
                                        item={item}
                                        value={value}
                                        name={name}
                                        units={units}
                                        containerClass="w-full h-24 2xl:h-36 rounded-lg mt-2 lg:mt-0"
                                        valuesClass="mt-1 2xl:mt-0 text-center text-white w-full h-full flex flex-col justify-around"
                                        iconStyle={iconStyle}
                                        iconSize="6x"
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const source = state.globalData.data["datos de calculo"];
  return {
    table_data: state.globalData.data["cotizacion estandar"],
    cards_data: source,
    solar_energy: source["grafica"][1]
  };
};

export default connect(mapStateToProps, {})(DatosCalculo);
