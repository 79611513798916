import { FETCH_FILE_DATA_SUCCESS, FETCH_FILE_DATA_FAILURE} from './filesDataTypes';

const initialState = {
    data: false,
    error: ''
}

const globalDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_FILE_DATA_SUCCESS:
            return {
                data: action.payload,
                error: ''
            }
            case FETCH_FILE_DATA_FAILURE:
                return {
                    data: [],
                    error: ''
                }
            default: return state
    }
}

export default globalDataReducer;