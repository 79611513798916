import { FETCH_VERSION_DATA_SUCCESS, FETCH_VERSION_DATA_FAILURE} from './versionsDataTypes';
import { getQuoteVersion, getClientId } from '../../handlers/handlers';
import { GETData } from '../../handlers/WebServices';

export const fetchVersionsDataSuccess = (data) => {
    return {
        type: FETCH_VERSION_DATA_SUCCESS,
        payload: data
    }
}

export const fetchVersionsDataFailure = (error) => {
    return {
        type: FETCH_VERSION_DATA_FAILURE,
        payload: error
    }
}

export const fetchQuoteVersions = () => {
    const version = getQuoteVersion();
    const id = getClientId();
    
    return (dispatch) => {
        GETData(`versions/${id}/${version}`)
        .then(response => {
            const data = response.data;
            dispatch(fetchVersionsDataSuccess(data));
        })
        .catch(error => {
            console.log('ERROR')
            dispatch(fetchVersionsDataFailure(error.message))
        })
    }
}