// Web services
import { SETData } from './WebServices';

// Helpers
import { ignoreZohoRegistry } from './handlers';

/*
    [
        "ErQte Visitó 1ra vez", //YA
        "ErQte Regresó a Visitar", //YA
        "ErQte Visitó Vencida", //YA
        "ErQte Descargó Doc Tecn", //YA
        "ErQte Interés An Financ", //YA
        "ErQte Interés Gráf. ROI", //YA
        "ErQte Interés Tecnologí", //YA
        "ErQte Interés Med Ambient", //YA
        "ErQte Clic Revisar PreC", //YA
        "ErQte Clic Firmar Contr", //YA
        "ErQte Clic Generar DocC", //YA
        "ErQte Clic Revisa tu Cot" //YA
    ]
*/

export const addNewTag = (idZoho, tag) => {
    if(!idZoho) {
        return;
    }

    if(ignoreZohoRegistry()) {
        return;
    }
    
    const body = {
        tag,
        "quoteId": idZoho
    }

    SETData(body, 'add_new_tag')
}

export const updateLastReview = (idZoho) => {
    if(!idZoho) {
        return;
    }

    if(ignoreZohoRegistry()) {
        return;
    }

    const body = {
        "quoteId": idZoho
    }

    SETData(body, 'update_last_review')
}