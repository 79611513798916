import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#92BD21";

const styles = StyleSheet.create({
  column: {
    flexDirection: "column",
    width: "90%",
  },
  columnLE: {
    flexDirection: "column",
    width: "100%",
  },
  rowEven: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(229, 232, 232, 0.4)",
    borderRadius: 5,
    alignItems: "center",
    height: 20,
    fontStyle: "bold"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 5,
    alignItems: "center",
    height: 20,
    fontStyle: "bold"
  },
  item: {
    width: "10%",
    color: "#7c7c7c",
    textAlign: "center"
  },
  itemYear: {
    width: "5%",
    color: "#7c7c7c",
    textAlign: "center"
  },
  itemPE: {
    width: "10%",
    color: "#7c7c7c",
    textAlign: "center"
  },
  itemLE: {
    width: "15%",
    color: "#7c7c7c",
    textAlign: "center"
  },
});

const FinancialTableBody = ({ data, year, activeLeasing }) => {
  const cop_format = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumSignificantDigits: 9
  });
  const number_format = Intl.NumberFormat("de-DE");

  const epcBody = () => {
    return (
      <View style={styles.column}>
        {data.map(
          (row, index) =>
            index <= year && (
              <View
                key={index}
                style={(index + 1) % 2 === 0 ? styles.rowEven : styles.row}
              >
                <Text style={styles.itemYear}>{index}</Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[0]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[1]))}
                </Text>
                <Text style={styles.itemPE}>
                  {number_format.format(Math.round(row[2]))}
                </Text>
                <Text style={styles.item}>
                  {number_format.format(Math.round(row[3]))}
                </Text>
                <Text style={styles.item}>
                  {number_format.format(Math.round(row[4]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[5]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[6]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[7]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[8]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[9]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[10]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[11]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[12]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[13]))}
                </Text>
                <Text style={styles.item}>
                  {cop_format.format(Math.round(row[14]))}
                </Text>
              </View>
            )
        )}
      </View>
    );
  }

  const leasingBody = () => {
    return (
      <View style={styles.columnLE}>
        {data.map(
          (row, index) =>
            index <= year && (
              <View
                key={index}
                style={(index + 1) % 2 === 0 ? styles.rowEven : styles.row}
              >
                <Text style={styles.itemLE}>{index}</Text>
                {
                  row.map(col => (
                    <Text style={styles.itemLE}>
                      {cop_format.format(Math.round(col))}
                    </Text>
                  ))
                }
              </View>
            )
        )}
      </View>
    );
  }

  return (
    activeLeasing ? leasingBody() : epcBody()
  )

  
};

export default FinancialTableBody;
