import React from "react";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet
} from "@react-pdf/renderer";

import { ercoLogo, epmLogo } from "../../utils/handlers/handlers";
import FinancialTableHeader from "./FinancialView/FinancialTableHeader";
import FinancialTableBody from "./FinancialView/FinancialTableBody";

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column"
  },
  logo: {
    width: 300,
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto"
  },
  container: {
    flexDirection: "row",
    marginTop: 18
  },
  containerText: {
    flexDirection: "column",
    marginTop: 18
  },
  title: {
    color: "#7c7c7c",
    letterSpacing: 2,
    fontSize: 18,
    textAlign: "center",
    textTransform: "uppercase"
  },
  titleVar: {
    color: "#7c7c7c",
    letterSpacing: 2,
    fontSize: 18,
    textTransform: "uppercase"
  },
  tableContainer: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 18,
    marginBottom: 18
  },
  text: {
    display: "block",
    color: "#7c7c7c",
    fontSize: 18
  },
  textDisclaimer: {
    display: "block",
    color: "#7c7c7c",
    fontSize: 12
  },
  textContent: {
    marginTop: 18,
    marginLeft: 110
  },
  tableHeaderLE: {
    width: "100%",
    height: "auto",
    flexDirection: "row",
    backgroundColor: "#92BD21",
    color: "#000",
    borderRadius: 5,
    fontStyle: "bold"
  },
  itemGroup: {
    width: "100px",
    padding: 0,
    textAlign: "center",
    wordBreak: 'break-word'
  }
});

const grayColor = '#BFBFBF'
const blueColor = '#9BC2E6'
const greenColor = '#70AD47'

const LeasingHeaderGroups = ({ vpnSolarSystem, vpnTraditionalEnergy, vpnProject }) => {
  return (
    <View style={styles.tableHeaderLE}>
      <Text style={{ width: '100px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '20px' }}></Text>
      <Text style={{ width: '400px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '20px' }}>Datos Sistema Solar</Text>
      <Text style={{ width: '500px', backgroundColor: blueColor, textAlign: 'center', paddingTop: '20px' }}>Costos Sistema Solar</Text>
      <Text style={{ width: '500px', backgroundColor: greenColor, textAlign: 'center', paddingTop: '20px' }}>Descuentos Tributarios Sistema Solar</Text>
      <Text style={{ width: '100px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '20px' }}>VPN Sistema Solar {"\n"}{vpnSolarSystem}</Text>
      <Text style={{ width: '100px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '10px' }}>Costos Energía Tradicional</Text>
      <Text style={{ width: '100px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '0px' }}>Descuentos Tributarios Energía Tradicional</Text>
      <Text style={{ width: '100px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '10px' }}>VPN Energía Tradicional {"\n"+vpnTraditionalEnergy}</Text>
      <Text style={{ width: '100px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '20px' }}>VPN Proyecto {"\n"+vpnProject}</Text>
      <Text style={{ width: '200px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '20px' }}>Comparativo Tarifa Energía</Text>
      <Text style={{ width: '200px', backgroundColor: grayColor, textAlign: 'center', paddingTop: '20px' }}>Ahorros Sistema Solar</Text>
    </View>
  )
}

const FinancialAnalysisPdf = ({ data, year, price, discount, excess, activeLeasing, vpnSolarSystem, vpnTraditionalEnergy, vpnProject, isEpm }) => {
  return (
    <Document>
      <Page size="A1" orientation="landscape" style={styles.page}>
        {!isEpm ?
          <Image src={ercoLogo} style={styles.logo} /> :
          <Image src={epmLogo} style={styles.logo} />
        }
        <View style={styles.container}>
          <Text style={styles.title}>Análisis Financiero</Text>
        </View>
        <View style={styles.tableContainer}>
          {
            activeLeasing && <LeasingHeaderGroups vpnSolarSystem={vpnSolarSystem} vpnTraditionalEnergy={vpnTraditionalEnergy} vpnProject={vpnProject} />
          }
          <FinancialTableHeader activeLeasing={activeLeasing} />
          <FinancialTableBody data={data} year={year} activeLeasing={activeLeasing} />
        </View>
        <View style={styles.containerText}>
          <Text style={styles.titleVar}>Variables</Text>
          <View style={styles.textContent}>
            <Text style={styles.text}>
              Horizonte del proyecto:{" "}
              <Text style={{ color: "#92BD21" }}>{year} años</Text>
            </Text>
            <Text style={styles.text}>
              Crecimiento precio KWh:{" "}
              <Text style={{ color: "#92BD21" }}>{price}%</Text>
            </Text>
            <Text style={styles.text}>
              Tasa de descuento / oportunidad:{" "}
              <Text style={{ color: "#92BD21" }}>{discount}%</Text>
            </Text>
            <Text style={styles.text}>
              Excedentes: <Text style={{ color: "#92BD21" }}>{excess}%</Text>
            </Text>
            <Text style={styles.textDisclaimer}>
              * El porcentaje de excedentes y su impacto en el flujo de caja corresponde a una estimacion
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FinancialAnalysisPdf;
