import { useState } from "react";

// Helpers
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../utils/handlers/handlers";

// Views
import CompleteReturnInvestment from "./CompleteReturnInvestment";
import MobileInfoCard from "./Mobile/MobileInfoCard";
import MobileSlider from "./Mobile/MobileSlider";
import MobileChart from "./Mobile/MobileChart";

function ReturnInvestment() {
    const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            {isMobile && <MobileInfoCard />}
            {isMobile && <MobileSlider setIsLoading={setIsLoading} />}
            {isMobile && <MobileChart isLoading={isLoading} />}
            {
                !isMobile && (
                    <CompleteReturnInvestment />
                )
            }
        </>
    );
}

export default ReturnInvestment;