import React, { useEffect, useState } from 'react'

export default function ContractPreview({ contract }) {

    const [url, setUrl] = useState('');

    useEffect(() => {
        if(!contract.url.includes('pdf') && contract.loaded) {
            setUrl(contract.url);
        }
    }, [contract]);
    
    return (
        <iframe id="contract-preview"
            title="Contract Preview"
            className="w-full overflow-auto h-96"
            src={url}>
        </iframe>
    )
}
