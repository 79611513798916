import React from "react";

// Styled Components
import Tooltip from './Tooltip';

// Utils
import { panama, tableHeaderInfo, tableHeaderInfoPanama } from '../../utils/handlers/handlers';
import { connect } from "react-redux";

function FinancialAnalysisTable({ city, cashflowInfo, year }) {  

  const validatePanama = city.includes("panama");
  
  const cop_format = Intl.NumberFormat(validatePanama ? "en-US" : "es-CO", {
    style: "currency",
    currency: validatePanama ? "USD" : "COP",
    maximumSignificantDigits: 9
  });

  const number_format = Intl.NumberFormat("de-DE");

  const headerData = validatePanama ? tableHeaderInfoPanama : tableHeaderInfo

  return (
    <table className="w-full h-full mb-5 mr-5 text-base">
      <thead>
        <tr className="h-16 px-10 text-base">
          {
            headerData.map((item, index) => (
              <th key={index} className="pt-0 px-5">
                <div className="flex flex-row">
                  <p className="w-full">{item.label}</p>
                  <div className="absolute top-2.5 right-1">
                    <Tooltip
                      tooltipText={item.tooltipText}
                    />
                  </div>
                </div>
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {cashflowInfo.map((row, index) => {
          if (index <= year) {
            return (
              <tr key={index} className="px-5">
                <td>{index}</td>
                {
                  row.map((value, index) => {
                    if(headerData[index+1]?.units === 'cash') {
                      return <td key={`${index}+sub`}>{cop_format.format(validatePanama ? value : Math.round(value))}</td>
                    } else if(headerData[index+1]?.units === '%') {
                      return <td key={`${index}+sub2`}>{Math.round(value * 100) / 100}%</td>
                    } else {
                      return <td key={`${index}+sub3`}>{number_format.format(Math.round(value))}</td>
                    }
                  })
                }
              </tr>
            );
          }
          <React.Fragment key={index}></React.Fragment>;
        })}
      </tbody>
    </table>
  );
}

const mapStateToProps = (state) => {
  return {
    city: state.globalData.data.cotizacion.ciudad,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialAnalysisTable);