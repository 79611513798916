import { FETCH_CONTRACT_DATA_SUCCESS, FETCH_CONTRACT_DATA_FAILURE} from './contractDataTypes';

const initialState = {
    data: {
        url: '',
        loaded: false,
        signed: false
    },
    error: ''
}

const contractDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_CONTRACT_DATA_SUCCESS:
            return {
                data: action.payload,
                error: ''
            }
        case FETCH_CONTRACT_DATA_FAILURE:
            return {
                data: [],
                error: ''
            }
        default: return state
    }
}

export default contractDataReducer;