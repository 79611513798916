import React from "react";

import ChartList from "../PartialComponents/ChartList";

const MobileChart = ({ isLoading }) => {
  return (
    <section className="returnInvestment w-screen h-screen md:ml-8 pt-11">
      <ChartList isLoading={isLoading} />
    </section>
  );
};

export default MobileChart;
