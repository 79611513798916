import React, { useState, useEffect } from "react";

import { Text, View, Image } from "@react-pdf/renderer";

import { styles } from "../../../utils/handlers/stylesPDF";
import { epmLogo } from "../../../utils/handlers/handlers";

const MaintenanceCostPdf = ({ data }) => {
  const [totalIva, setTotalIva] = useState("");
  
  // const amount = data['cotizacion estandar'].filter(el => el['Descripción'].toLowerCase() === 'mantenimiento');

  const cop_format = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumSignificantDigits: 12
  });

  useEffect(() => {
    const costoMantenimiento =
      data["retorno de inversion"].chartData["chart data"]["calculation data"]
        .costo_mantenimiento * 2
    const iva = 1.19;
    setTotalIva(Math.round(costoMantenimiento * iva).toString());
    // setTotalIva((costoMantenimiento).toString());
  }, [data]);

  const tableData = [
    {
      description: "Costo mantenimiento primer año",
      amount: 2,
      total: cop_format.format(totalIva)
    }
  ];

  const text = {
    info: "En EPM te damos la opción de cotizar el plan de mantenimiento para el sistema fotovoltaico a su medida (costo adicional a la propuesta solar). El mantenimiento consta de:",
    items: [
      { item: "Verificación del estado de los módulos." },
      {
        item: "Verificación del estado de la estructura y elementos de sujeción."
      },
      { item: "Ajuste de la estructura y elementos de sujeción." },
      { item: "Limpieza de módulos fotovoltaicos." },
      { item: "Verificación parámetros del inversor." },
      { item: "Verificación de la acometida eléctrica (Conexiones)." },
      { item: "Entrega de informe al cliente." }
    ]
  };
  return (
    <>
    <View style={styles.empIconContentAbsolute}>
      <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
    </View>
    <View style={styles.containerInvestment}>
      <Text style={styles.titleLeft}>Costo de Mantenimiento</Text>
      <View style={styles.tableHeaderInv}>
        <Text style={styles.itemInvestmentI}>Ítem</Text>
        <Text style={styles.itemInvestmentDescription}>Descripción</Text>
        <Text style={styles.itemInvestmentAmount}>Cantidad</Text>
        <Text style={styles.itemInvestment}>Total IVA incluido</Text>
      </View>
      {tableData.map(({ description, amount, total }, i) => (
        <View
          key={i}
          style={
            (i + 1) % 2 === 0 ? styles.tableRowInvEven : styles.tableRowInv
          }
        >
          <Text style={styles.itemInvestmentI}>{i + 1}</Text>
          <Text style={styles.itemInvestmentDescription}>{description}</Text>
          <Text style={styles.itemInvestmentAmount}>{amount}</Text>
          <Text style={styles.itemInvestment}>{total}</Text>
        </View>
      ))}
      <View style={styles.itemsContent}>
        <Text>{text.info}</Text>
        {text.items.map(({ item }, i) => (
          <View key={i} style={styles.textContent}>
            <Text style={styles.point}>•</Text>
            <Text style={styles.text}>{item}</Text>
          </View>
        ))}
      </View>
    </View>
    </>
  );
};

export default MaintenanceCostPdf;
