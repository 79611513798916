import React from "react";

import { Text, View, Image } from "@react-pdf/renderer";

import { styles } from "../../../utils/handlers/stylesPDF";
import { epmLogo } from "../../../utils/handlers/handlers";

const ListingConditions = ({ isPrequote }) => {
  const items = [
    {
      item: "Es necesario el cumplimiento de la resolución CREG 038 de 2014 en articulo 19 sobre la ubicación de las fronteras comerciales. La presenta oferta no contempla trabajos de adecuación de la frontera comercial que sean exigidos por el Operador de Red."
    },
    /*{
      item: "Es necesario el cumplimiento de la resolución CREG 038 de 2014 en articulo 19 sobre la ubicación de las fronteras comerciales."
    },*/
    {
      item: "El sistema planteado en esta oferta es interconectado a la red, por lo cual, en caso de presentarse una interrupción en la red eléctrica, el sistema solar no prestará servicio, la desconexión y reconexión del sistema solar se hará de manera automática."
    },
    {
      item: "No se incluye compensación por penalización por energía reactiva, se cotizara tan pronto el sistema este generando."
    },
    {
      item: "Esta propuesta no contempla baterías para almacenamiento de energía. No funciona como respaldo."
    },
    {
      item: "La capacidad estructural debe ser de 16 kg/m2 para cubierta y 45 kg/m2 para losa."
    },
    {
      item: "Los valores de generación presentados en la propuesta son aproximados y pueden variar según el cambio de las condiciones climáticas en los diferentes meses del año."
    },
    // {
    //   item: "El porcentaje de impacto sobre los consumos es una valor de referencia calculado con los consumos suministrados por el cliente al momento de la fecha, en caso de aumentar o disminuir los consumos en el futuro este"
    // },
    {
      item: "El porcentaje de impacto sobre los consumos, es un valor de referencia calculado con el consumo suministrado por el cliente al momento de la cotización, en caso de aumentar o disminuir este valor, el impacto puede cambiar, por lo tanto, no se garantiza en el tiempo."
    },
    {
      item: "El porcentaje de impacto sobre los consumos es un valor de referencia calculado con los consumos suministrados a la fecha."
    },
  ];

  const itemsPrequote = [
    {
      item: "Los valores presentados en la precotización están sujetos a cambios."
    }
  ]
  return (
    <>
    <View style={styles.empIconContentAbsolute}>
      <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
    </View>
    <View style={styles.containerInvestment}>
      <Text style={styles.titleLeft}>Condiciones de cotización</Text>

      <View style={{ ...styles.itemsContent, marginRight: 12, paddingTop: 0 }}>
      {items.map(({ item }, i) => (
        <View key={i} style={styles.textContent}>
          <Text style={styles.point}>•</Text>
          <Text style={styles.textConditions}>{item}</Text>
        </View>
      ))}
      {isPrequote && (
        itemsPrequote.map(({ item }, i) => (
          <View key={i} style={styles.textContent}>
            <Text style={styles.point}>•</Text>
            <Text style={styles.textConditions}>{item}</Text>
          </View>
        ))
      )}
      </View>
    </View>
    </>
  );
};

export default ListingConditions;
