import React from "react";
import { connect } from "react-redux";

import Table from "../PartialComponents/Table";

const TableMobile = ({ table_data }) => {
  const columns = ["Descripción", "Cantidad"];
  const system_index = table_data.findIndex((data) =>
    data["Descripción"].includes("Sistema Solar")
  );
  const tableData = table_data.slice(0, system_index);
  return (
    <section
      id="calculationData"
      className="calculationData flex w-screen overscroll-auto overflow-x-scroll items-center mb-4"
    >
      <Table columns={columns} data={tableData} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    table_data: state.globalData.data["cotizacion estandar"],
  };
};

export default connect(mapStateToProps, {})(TableMobile);
