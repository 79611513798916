import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import ROIChartTooltip from './ROIChartTooltip';

export default function ROIChart({ newROIData }) {

    const screenWidth = window.innerWidth;
    let options = {};

    if(screenWidth < 1024) {
        options.anchor = 'bottom'
        options.direction = 'row'
        options.translateX = 0
        options.translateY = 90
        options.top = 10
        options.right = 10
        options.bottom = 120
        options.left = 80
    } else {
        options.anchor = 'bottom-right'
        options.direction = 'column'
        options.translateX = 160
        options.translateY = -10
        options.top = 0
        options.right = 60
        options.bottom = 160
        options.left = 90
    }

    const axisConfig = {
        bottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Año',
            legendPosition: 'middle',
            legendOffset: 42
        },
        left: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Flujo de Caja',
            legendPosition: 'middle',
            legendOffset: -64
        }
    }

    return (
        <div className="chart-container">
            <ResponsiveBar
                data={newROIData}
                keys={[ 'cash flow' ]}
                indexBy="year"
                margin={{ top: options.top, right: options.right, bottom: options.bottom, left: options.left }}
                padding={0.3}
                colors={{ datum: 'data.color' }}
                axisBottom={axisConfig.bottom}
                axisLeft={axisConfig.left}
                enableLabel={false}
                tooltip={({ data }) => (
                    <ROIChartTooltip data={data} />
                )}
                animate={true}
                motionDamping={10}
                motionStiffness={80}
            />
        </div>
    )
}
