import { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./styles/main.css";

import { useMediaQuery } from "react-responsive";

// Main components
import Navbar from "./components/Navbar";
import HomeExpired from "./components/HomeExpired";
import CalculationData from "./components/CalculationData";
import InvestmentCost from "./components/InvestmentCost";
import ReturnInvestment from "./components/ReturnInvestment";
import Technology from "./components/Technology";
import Environment from "./components/Environment";
import Sign from "./components/Sign";
import WhatsappButton from "./components/WhatsappButton";
import ToTopButton from "./components/ToTopButton";
import ButtonToPrintPage from "./components/PartialComponents/ButtonToPrintPage";

import Home from "./components/Home";
import Experience from "./components/Experience";
import OurPartner from "./components/OurPartner";
import PartnerCompany from "./components/PartnerCompany";

import ErColombia from "./components/Mobile/ErColombia";
import TableMobile from "./components/Mobile/TableMobile";
import MobilePanelCard from "./components/Mobile/MobilePanelCard";
import MobileDataCards from "./components/Mobile/MobileDataCard";

// Styled components
import Loader from "react-loader-spinner";

// Utils
import { fetchGlobalData } from "./utils/redux";
import smoothScrolling from "./utils/handlers/smoothScrolling";
import { addNewTag, updateLastReview } from "./utils/handlers/zohoIntegration";
import {
  validateDate,
  DeviceSize,
  validateAllowView,
} from "./utils/handlers/handlers";

function App({
  fetchGlobalData,
  fecha_expiracion,
  _idZoho,
  allowedROI,
  source,
  state,
  isEpm,
}) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const [isExpired, setIsExpired] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allowROI, setAllowROI] = useState(validateAllowView(allowedROI));
  
  useEffect(() => {
    smoothScrolling();
    fetchGlobalData();
    if (fecha_expiracion) {
      setLoaded(true);
    }
  }, [setLoaded, fecha_expiracion, fetchGlobalData]);

  useEffect(() => {
    if (fecha_expiracion) {
      setIsExpired(validateDate(fecha_expiracion));
      if (validateDate(fecha_expiracion)) {
        addNewTag(_idZoho, "ErQte Visitó Vencida");
      }
    }
  }, [fecha_expiracion, _idZoho]);

  useEffect(() => {
    addNewTag(_idZoho, "ErQte Visitó 1ra vez");
    addNewTag(_idZoho, "ErQte Regresó a Visitar");
    updateLastReview(_idZoho);
  }, [_idZoho]);

  useEffect(() => {
    setAllowROI(allowedROI);
  }, [allowedROI]);

  return loaded ? (
    <div className="App w-auto h-auto overflow-x-hidden overscroll-auto">
      <div>
        <Navbar />
        {isExpired ? <HomeExpired /> : <Home />}
        {!isEpm && (
          <div className="wt flex flex-no-wrap">
            {/* {isMobile && <ErColombia />} */}
            <Experience isMobile={isMobile} />
            {/* <OurPartner />
              {!isMobile && <PartnerCompany />} */}
          </div>
        )}
        {isMobile && <TableMobile />}
        {isMobile && <MobilePanelCard />}
        {isMobile && <MobileDataCards />}
        {!isMobile && <CalculationData />}
        <Technology />
        <InvestmentCost allowedROI={allowedROI} />
        {allowROI !== false && <ReturnInvestment />}
        <Environment />
        {!isEpm && <Sign isExpired={isExpired} />}
        <WhatsappButton />
        <ButtonToPrintPage isExpired={isExpired} />
        <ToTopButton />
      </div>
    </div>
  ) : (
    <div className="w-screen h-screen flex">
      <Loader
        className="mx-auto my-auto"
        type="TailSpin"
        color="#92BD21"
        width={80}
        height={80}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const source = state.globalData.data;
  return {
    source,
    state,
    fecha_expiracion: source.cotizacion.fecha_expiracion,
    isEpm: source.cotizacion.isEpm,
    _idZoho: source.cotizacion._idZoho,
    allowedROI: source["retorno de inversion"].allowROI,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalData: () => dispatch(fetchGlobalData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
