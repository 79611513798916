import { FETCH_ADVISER_DATA_SUCCESS, FETCH_ADVISER_DATA_FAILURE} from './adviserDataTypes';
import adviser from '../../handlers/consultant.json';

const initialState = {
    data: [],
    error: ''
}

const adviserDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_ADVISER_DATA_SUCCESS:
            return {
                data: action.payload,
                error: ''
            }
            case FETCH_ADVISER_DATA_FAILURE:
                return {
                    data: adviser,
                    error: ''
                }
            default: return state
    }
}

export default adviserDataReducer;