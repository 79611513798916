import { CHANGED_LEASING } from "./typeOperationType";
  
  const initialState = {
    leasing: false,
  };
  
  const typeOperationReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGED_LEASING:
        return {
          ...state,
          leasing: !state.leasing
        };
      default:
        return state;
    }
  };
  
  export default typeOperationReducer;