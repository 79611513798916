import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#DFDE35";

const styles = StyleSheet.create({
  tableHeader: {
    width: "90%",
    flexDirection: "row",
    backgroundColor: "#92BD21",
    borderRadius: 5,
    padding: 5,
    height: "auto",
    fontStyle: "bold",
    color: "#fff"
  },
  tableHeaderLE: {
    width: "100%",
    height: "auto",
    flexDirection: "row",
    backgroundColor: "#92BD21",
    color: "#000",
    borderRadius: 5,
    padding: 0,
    fontStyle: "bold"
  },
  item: {
    width: "10%",
    textAlign: "center"
  },
  itemYear: {
    width: "5%",
    textAlign: "center"
  },
  itemPE: {
    width: "10%",
    textAlign: "center"
  },
  itemLE: {
    width: "100px",
    padding: 3,
    textAlign: "center",
    wordBreak: 'break-word'
  }
});
const grayColor = '#BFBFBF'
const blueColor = '#9BC2E6'
const greenColor = '#70AD47'

const FinancialTableHeader = ({ activeLeasing }) => {

  const epcHeader = () => {
    return (
      <View style={styles.tableHeader}>
        <Text style={styles.itemYear}>Año</Text>
        <Text style={styles.item}>Costo Energía [COP/kWh]</Text>
        <Text style={styles.item}>Tarifa Comercialización</Text>
        <Text style={styles.itemPE}>Producción Energía [kWh/año]</Text>
        <Text style={styles.item}>Energía Inyectada [kWh/año]</Text>
        <Text style={styles.item}>Energía Autoconsumida [kWh/año]</Text>
        <Text style={styles.item}>Inversión [COP*1000]</Text>
        <Text style={styles.item}>Mantenimiento [COP*1000]</Text>
        <Text style={styles.item}>Cargo por Respaldo</Text>
        <Text style={styles.item}>Ahorro Energía</Text>
        <Text style={styles.item}>Ahorro Energía Inyectada</Text>
        <Text style={styles.item}>Recuperación IVA [COP*1000]</Text>
        <Text style={styles.item}>Descuento Tributario [COP*1000]</Text>
        <Text style={styles.item}>Depreciación acelerada [COP*1000]</Text>
        <Text style={styles.item}>Flujo de caja [COP*1000]</Text>
        <Text style={styles.item}>Flujo de caja acumulado [COP*1000]</Text>
      </View>
    )
  }

  const leasingHeader = () => {
    return (
      <View style={styles.tableHeaderLE}>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: grayColor }}>Año</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: grayColor }}>Tarifa Energía [COP/kWh]</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: grayColor }}>Tarifa Excedentes</Text>
        <Text style={{...styles.itemLE, paddingTop: '0px', backgroundColor: grayColor }}>Excedentes de Energía [kWh/año]</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: grayColor }}>Energía Autoconsumida [kWh/año]</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: blueColor }}>O & M</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: blueColor }}>Seguro</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: blueColor }}>Cargo por Respaldo</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: blueColor }}>Cuota</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: blueColor }}>Opcion de compra</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: greenColor }}>BT Depreciación</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: greenColor }}>BT Intereses</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: greenColor }}>BT Gastos</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: greenColor }}>Descuento IVA en compras</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: greenColor }}>BT Ley 1715</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: grayColor }}>Flujo de caja Energía Solar</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: grayColor }}>Pago Energía Tradicional</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: grayColor }}>BT Pago Energía</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: grayColor }}>Flujo de caja</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: grayColor }}>Flujo neto proyecto</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: grayColor }}>Tarifa Energía Solar (COP/kWh)</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: grayColor }}>Energía Tradicional (COP/kWh)</Text>
        <Text style={{...styles.itemLE, paddingTop: '10px', backgroundColor: grayColor }}>Ahorro pago Energía</Text>
        <Text style={{...styles.itemLE, paddingTop: '20px', backgroundColor: grayColor }}>Venta Excedentes</Text>
      </View>
    )
  }

  return (
    activeLeasing ? leasingHeader() : epcHeader()
  );
};

export default FinancialTableHeader;
