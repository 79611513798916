import React from "react";
import { formatCurrency } from "../../utils/handlers/handlers";

export default function Tabla({ columns, data, id, sizes }) {

  if(columns.length === 2){ //Tabla de items
    const ignoreItem = "otros items"
    const index = data?.findIndex( x => x['Descripción'].toLowerCase() === ignoreItem );
    index!==-1 && data?.splice( index, 1 ); 
  }
  return (
    <table className="w-full md:overflow-x-hidden" id={id}>
      <thead>
        <tr className="px-10">
          <th
            className="px-10 hidden md:table-cell"
            style={sizes && { width: sizes[0] }}
          >
            Ítem
          </th>
          {columns.map((col, index) => (
            <th
              key={index}
              className="text-center"
              style={sizes && { width: sizes[index + 1] }}
            >
              {col}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td className="hidden md:table-cell" style={sizes && { width: sizes[0] }}>
              {index + 1}
            </td>
            {columns.map((col, index) => (
              <td key={index} style={sizes && { width: sizes[index + 1] }}>
                {col=== 'Total' ? formatCurrency(item[col]) : item[col]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
