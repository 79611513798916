import React from 'react';
import { StaticDialog, ModalContent } from 'react-st-modal';

// Utils
import OtherConsiderations from './OtherConsiderations';
import ComercialConsiderations from './ComercialConsiderations';
import { termsAndConditions } from '../../utils/handlers/handlers';

// Styled Components
import Button from 'react-bootstrap/Button';

export default function TermsAndConditions({ quoteType, openModal, handleClose, guarantee, setReviewdTerms, generateContract, city }) {

    let considerations = termsAndConditions(city);
    if(quoteType === 'residencial') {
        considerations = considerations.slice(0, -1);
    }

    const closeModal = () => {
        setReviewdTerms(true);
        handleClose();
    }

    return (
        <StaticDialog
            className="terms-conditions"
            isOpen={openModal}
            isCanClose={false}
            showCloseIcon={true}
            onAfterClose={() => closeModal()}
            title="Terminos y condiciones"
        >
            <ModalContent>
                <div className="tems-content-modal w-full h-96 mt-4 overflow-auto">
                    <ComercialConsiderations guarantee={guarantee} city={city} />
                    <OtherConsiderations considerations={considerations} />
                    {
                        generateContract ?
                        <div className="w-2/5 h-12 my-4 mx-auto">
                            <Button
                                bsPrefix="approve toContract px-10"
                                    onClick={() => closeModal()}
                                >
                                Revisar Precontrato
                            </Button>
                        </div> : 
                        <></>
                    }
                </div>
            </ModalContent>
        </StaticDialog>
    );
}