import { FETCH_ADVISER_DATA_SUCCESS, FETCH_ADVISER_DATA_FAILURE} from './adviserDataTypes';
import { getQuoteVersion, getClientId } from '../../handlers/handlers';
import { GETData } from '../../handlers/WebServices';

export const fetchAdviserDataSuccess = (data) => {
    return {
        type: FETCH_ADVISER_DATA_SUCCESS,
        payload: data
    }
}

export const fetchAdviserDataFailure = (error) => {
    return {
        type: FETCH_ADVISER_DATA_FAILURE,
        payload: error
    }
}
export const fetchAdviserData = () => {
    const version = getQuoteVersion();
    const id = getClientId();
  
    return async (dispatch) => {
      try {
        const fetchDataPromise = GETData(`adviser/${id}/${version}`);
        const timeoutPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error('Tiempo de espera agotado'));
          }, 7000); 
        });
        const response = await Promise.race([fetchDataPromise, timeoutPromise]);
        const data = response.data;
        dispatch(fetchAdviserDataSuccess(data));
      } catch (error) {
        if (error.message === 'Tiempo de espera agotado') {
          dispatch(fetchAdviserDataFailure(null));
        } else {
          console.log('ERROR:', error);
          dispatch(fetchAdviserDataFailure(error.message));
        }
      }
    };
  };