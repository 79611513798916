import React, { useMemo } from "react";

// Styled Components
import Tooltip from './Tooltip';

// Utils
import { useTable } from 'react-table'
import { leasingTableGroups } from '../../utils/handlers/handlers';

export default function LeasingAnalysisTable({ cashflowInfo, year, vpnSolarSystem, vpnTraditionalEnergy, vpnProject }) {  
  
  const cop_format = Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    maximumSignificantDigits: 9
  });

  const number_format = Intl.NumberFormat("de-DE");

  const columns = useMemo(() => {
    const groups = leasingTableGroups(vpnSolarSystem, vpnTraditionalEnergy, vpnProject)
    return groups.map(group => group)
  }, [vpnSolarSystem, vpnTraditionalEnergy, vpnProject]);

  const { headerGroups } = useTable({
    columns,
    data: [],
  })
  
  return (
    <table className="w-full h-full mb-5 mr-5 text-base overflow-auto">
      <thead className="w-auto">
        {headerGroups.map((group, index) => (
          <tr key={index} className="leasing-row h-16 px-10 text-base">
            {group.headers.map((column, key) => {
              let styles = {}
              if(index === 0) {
                styles = {
                  width: column.width,
                  backgroundColor: column.backgroundColor,
                  color: column.textColor,
                  borderTopRightRadius: '10px',
                  borderTopLeftRadius: '10px',
                  fontWeight: '700',
                  padding: '5px'
                }
              } else {
                styles = {
                  backgroundColor: column.parent.backgroundColor,
                  color: column.parent.textColor,
                  fontWeight: '600',
                  fontSize: '0.9rem',
                  padding: '0px 8px'
                }
              }

              if(index === 0) {
                return (
                  <th
                  key={key}
                  className="leasing"
                  {...column.getHeaderProps()}
                  style={styles}
                >
                  {column.render("Header")}
                  {column.Header2 &&
                    <>
                      <br/>
                      <p className="w-full px-3 font-black text-red-600">{column.render("Header2")}</p>
                    </>
                  }
                </th>
                )
              }

              return (
                <th
                  key={key}
                  className="leasing"
                  {...column.getHeaderProps()}
                  style={styles}
                >
                  <div className="flex flex-row">
                    <p className="w-full px-3">{column.render("Header")}</p>
                    <div className="absolute top-2.5 right-1">
                      <Tooltip
                        tooltipText={column.tooltipText}
                      />
                    </div>
                  </div>
                </th>
              )
            })}
          </tr>
          ))}
      </thead>
      <tbody>
      {cashflowInfo.map((row, index) => {
          if (index <= year) {
            return (
              <tr key={index} className="px-5">
                <td>{index}</td>
                {
                  row.map((value, key) => {
                    if(headerGroups[1].headers[key+1].units === 'cash') {
                      return <td key={key}>{cop_format.format(Math.round(value))}</td>
                    } else if(headerGroups[1].headers[key+1].units === '%') {
                      return <td key={key}>{Math.round(value * 100) / 100}%</td>
                    } else {
                      return <td key={key}>{number_format.format(Math.round(value))}</td>
                    }
                  })
                }
              </tr>
            );
          }
          return <></>;
        })}
      </tbody>
    </table>
  )
}