import React, {useRef} from 'react';
import Button from 'react-bootstrap/Button';
import SignatureCanvas from 'react-signature-canvas';

export default function SignatureManual(props) {

    const signatureRef = useRef({});

    const saveSignature = (signature) => {
        props.setSignature(signature);
    }

    return (
        <div className="w-full mx-auto flex flex-col justify-center">
            <h1 className="mx-auto mb-4">Firmar Digital</h1>
            <SignatureCanvas
                ref={signatureRef}
                penColor='black'
                backgroundColor='#fff'
                canvasProps={{width: 315, height: 100, className: 'sigCanvas mx-auto rounded-lg'}}
                onEnd={()=>(
                    saveSignature(signatureRef.current.getTrimmedCanvas().toDataURL('signature/jpg'))
                )}
            />
            <Button
                className="w-1/3 h-12 mx-auto mt-4 rounded-lg approve"
                onClick={() => {
                    signatureRef.current.clear();
                    saveSignature(null);
                }}
            >
                Limpiar
            </Button>
        </div>
    )
}
