import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";

// Utils
import { styles } from "../../../utils/handlers/stylesPDF";
import { commConditions, epmLogo } from "../../../utils/handlers/handlers";

const CommercialConditionsPdf = () => {
  const items = [
    {
      item: "La aceptación de esta propuesta constituye una vinculación contractual.",
    },
    {
      item: "50% de anticipo y 50% a contra entrega finalizado el proyecto (Aplica solo para compra del sistema).",
    },
    // {
    //   item: "Posibilidad de financiación del 100% del valor a través de SOMOS.",
    // },
  ];

  return (
    <>
      <View style={styles.empIconContentAbsolute}>
        <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
      </View>
      <View style={styles.containerComercialConditionsPdf}>
        <Text style={styles.titleLeft}>Condiciones Comerciales</Text>
        <View style={styles.containerImg}>
          <Image style={styles.containerImg} src={commConditions} alt="CommercialConditions" />
        </View>
        <View style={styles.itemsContent}>
          {items.map(({ item }, i) => (
            <View key={i} style={styles.textContent}>
              <Text style={styles.point}>•</Text>
              <Text style={styles.text}>{item}</Text>
            </View>
          ))}
        </View>
      </View>
    </>
  );
};

export default CommercialConditionsPdf;
