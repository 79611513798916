import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Styled components
import Select from 'react-select';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Switch from "react-switch";

// Partial components
import ROIChart from './ROIChart';
import EnergyCostChart from './EnergyCostChart';

// Utils
import { selectStyles } from '../../utils/handlers/handlers';
import { changedLeasingSucces } from '../../utils/redux';

function ChartList({ ROIData, energyData, isLoading, activeLeasing, setActiveLeasing, quoteType, allowLeasing }) {
    const [option, setOption] = useState("ROI");
    
    const chartOptions = [
        { value: 'ROI', label: 'ROI' },
        { value: 'Enery cost', label: 'Costo de Energia' }
    ];

    const [newROIData, setNewROIData] = useState([]);
    const [newEnergyData, setNewEnergyData] = useState([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if(!isLoading) {
            setTimeout(() => {
                setIsReady(true)
            }, 500)
            setNewROIData(ROIData)
            setNewEnergyData(energyData)
        } else {
            setIsReady(false)
        }
    }, [isLoading, ROIData, energyData])

    return (
        <div className="w-full h-full flex flex-col">
            <div className="ml-8 mb-4 flex flex-col md:flex-row items-center">
                {
                    quoteType !== 'residencial' && allowLeasing && (
                        <div className='flex flex-row w-full md:w-auto justify-between pl-10 md:pl-0 pr-20 md:pr-0'>
                            <p className={`text-base ${!activeLeasing ? 'text-gray-600 font-semibold' : 'text-gray-400'}`}>EPC</p>
                            <Switch
                                className='mx-2'
                                onChange={() => setActiveLeasing()}
                                checked={activeLeasing}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor='#4FAC2E'
                            />
                            <p className={`text-base ${activeLeasing ? 'text-gray-600 font-semibold' : 'text-gray-400'}`}>Leasing</p>
                        </div>
                    )
                }
                <Select
                    className="w-56 ml-0 md:ml-12 mt-6 md:mt-0"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="charts"
                    options={chartOptions}
                    onChange={option => setOption(option.label)}
                    placeholder="Selecciona la grafica ..."
                    styles={selectStyles}
                />
            </div>
            <div className="w-full h-full">
                {
                    option === 'ROI' ?
                    <>
                        {
                            isLoading || !isReady ?
                            <div className="w-full h-full">
                                <ROIChart newROIData={newROIData} />
                                <div className="chart-loader">
                                    <Loader
                                        className="loader w-full h-full my-auto"
                                        type="TailSpin"
                                        color="#92BD21"
                                        width={80}
                                        height={80}
                                    />
                                </div>
                            </div> :
                            <ROIChart newROIData={newROIData} />
                        }
                    </> :
                    <>
                        {
                            isLoading || !isReady ?
                            <div className="w-full h-full">
                                <EnergyCostChart energyData={newEnergyData} />
                                <div className="chart-loader">
                                    <Loader
                                        className="loader w-full h-full my-auto"
                                        type="TailSpin"
                                        color="#92BD21"
                                        width={80}
                                        height={80}
                                    />
                                </div>
                            </div>:
                            <EnergyCostChart energyData={newEnergyData} />
                        }
                    </>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ROIData: state.chartsData.data.ROI,
        energyData: state.chartsData.data.energy,
        quoteType: state.globalData.data.cotizacion.tipo,
        allowLeasing: state.globalData.data.cotizacion.leasing,
        activeLeasing: state.typeOperation.leasing
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveLeasing: () => dispatch(changedLeasingSucces()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartList)