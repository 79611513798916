import React, { useState } from 'react';
import { connect } from 'react-redux';
import { panama } from '../../utils/handlers/handlers';

function EnergyCostChartTooltip({ city, sliceData }) {

    const [unit] = useState(city.includes("panama") ? 'USD' : 'COP')

    return (
        <div className="lg:h-24 w-auto bg-white rounded-md shadow-md">
            <h1 className="mx-3 mb-1">
                Año:
                <span className="ml-2 font-semibold">
                    {sliceData.points[0].data.x}
                </span>
            </h1>
            {
                sliceData.points.map(point => (
                    <div className="flex mx-3 mb-2">
                        <div
                            className="w-4 h-4 rounded-full mt-1 mr-1.5"
                            style={{backgroundColor: point.color}}
                        >
                        </div>
                        <h1>Proyeccion {point.serieId}:
                            <span className="mx-3 font-semibold">$ {point.data.yFormatted} {unit}</span>
                        </h1>
                    </div>
                ))
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      city: state.globalData.data.cotizacion.ciudad,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EnergyCostChartTooltip);
