import React, { useEffect } from "react";

// Utils
import { infoIcon } from '../../utils/handlers/handlers';

// Styled Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const Tooltip = ({ tooltipText }) => {

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return (
        <>
            <div
                style={{
                    fontSize: '0.6rem',
                    cursor: 'pointer'
                }}
                data-tip={tooltipText}
                data-event='click focus'
                data-for='tooltip'
            >
                <FontAwesomeIcon
                    icon={infoIcon}
                    color="white"
                    size="2x"
                />
            </div>
            <ReactTooltip
                id="tooltip"
                globalEventOff='click'
                place="right"
                multiline={true}
            />
        </>
    );
}

export default Tooltip;