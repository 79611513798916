import React from 'react';
import { connect } from 'react-redux';

// Styled components
import { ResponsivePie } from '@nivo/pie';


function PieChart({ pieData }) {

    const data = pieData.map( item => {
        if (Number(item.value) > 100) {
            return {
                ...item,
                value: '100.00'
            }
        }
        if (Number(item.value) < 0) {
            return {
                ...item,
                value: '0.00'
            }
        }
        /*if (item.color == '#A2C52B'){
            return {
                ...item,
                color: '#A2C52B'
            }
        }*/
        return item;
    });

    const legends = [
        {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 14,
            translateY: 36,
            itemsSpacing: 8,
            itemWidth: 100,
            itemHeight: 16,
            itemTextColor: '#737373',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'square'
        }
    ]

    return (
        <div id="pie-chart" className="w-full h-full">
            <ResponsivePie
                data={data}
                margin={{ top: 0, right: 24, bottom: 70, left: 24 }}
                innerRadius={0.6}
                padAngle={0.2}
                cornerRadius={1}
                colors={{ datum: 'data.color' }}
                borderWidth={0}
                enableRadialLabels={false}
                enableSliceLabels={false}
                legends={legends}
            />
        </div>
    )
}

const mapStateToProps = state => {
    const source = state.globalData.data['datos de calculo'];
    return {
        pieData: source.grafica.map( item => (
            {
                ...item,
                value: item.value.toFixed(2)
            }
        ))
    }
}

export default connect(mapStateToProps, {})(PieChart)