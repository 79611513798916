import React, { useState, useEffect } from "react";
import { ercoLogo, epmLogo } from "../utils/handlers/handlers";
import { connect } from "react-redux";

// Utils
import { motion } from "framer-motion";
// import { gsap } from "gsap";
// import { funcionObserver } from "../utils/handlers/animationPage";
import { validateAllowView } from '../utils/handlers/handlers';

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeLinecap="round"
    strokeWidth="3"
    {...props}funcionObserver
  />
);
const transition = { duration: 0.33 };

function Navbar({ firmar, allowedROI, isEpm }) {
  const [isHidden, setIsHidden] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [allowROI] = useState(validateAllowView(allowedROI));

  // useEffect(() => {
  //   const sectionsLimit = gsap.utils.toArray("section");
  //   const menuItems = gsap.utils.toArray(".menu-item");

  //   const observer = new IntersectionObserver(
  //     (entries) => funcionObserver(entries, menuItems),
  //     {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.3
  //     }
  //   );
  //   sectionsLimit.forEach((section) => observer.observe(section));
  // }, []);

  const toggleElements = () => {
    let classes = document.querySelector(".nav-items").className;
    if (!isHidden) {
      document.querySelector(".nav-items").className = classes.replace(
        "flex",
        "hidden"
      );
      setOpen(false);
      setIsHidden(true);
    } else {
      document.querySelector(".nav-items").className = classes.replace(
        "hidden",
        "flex"
      );
      setOpen(true);
      setIsHidden(false);
    }
  };

  return (
    <header className="navbar fixed top-0 flex flex-wrap flex-row lg:justify-evenly lg:items-center justify-between bg-white py-6 px-6 w-screen z-50">
      <a
        href="https://www.ercoenergia.com.co/"
        target="_blank"
        rel="noreferrer"
        className="lg:w-1/12"
      >
        <img className="h-10 lg:h-8" src={isEpm ? epmLogo : ercoLogo} alt="Logo" />
      </a>
      <a href="#home" className="hidden">
        {" "}
      </a>
      <button
        className="inline-block lg:hidden w-8 h-8 text-gray-600 p-1 mb-4"
        type="button"
        onClick={() => toggleElements()}
      >
        <svg width="30" height="30" viewBox="0 0 23 23">
          <Path
            className="open"
            animate={isOpen ? "open" : "closed"}
            initial={false}
            variants={{
              closed: { d: "M 2 2.5 L 20 2.5", stroke: "hsl(0, 0%, 45%)" },
              open: { d: "M 3 16.5 L 17 2.5", stroke: "hsl(77, 70%, 44%)" }
            }}
            transition={transition}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            stroke="hsl(0, 0%, 45%)"
            animate={isOpen ? "open" : "closed"}
            initial={false}
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 }
            }}
            transition={transition}
          />
          <Path
            animate={isOpen ? "open" : "closed"}
            initial={false}
            variants={{
              closed: {
                d: "M 2 16.346 L 20 16.346",
                stroke: "hsl(0, 0%, 45%)"
              },
              open: { d: "M 3 2.5 L 17 16.346", stroke: "hsl(77, 70%, 44%)" }
            }}
            transition={transition}
          />
        </svg>
      </button>
      <nav className="nav-items lg:relative lg:top-0 z-20 hidden lg:flex flex-col lg:flex-row justify-center lg:space-x-8 w-5/6 bg-white text-font-grey md:text-md lg:bg-transparent pt-6 lg:p-0">
        {
          !isEpm && (
            <a className="block menu-item" href="#experience" data-url="exp-erco">
              Experiencia ERCO
            </a>
          )
        }
        <a
          className="block menu-item"
          href="#calculationData"
          data-url="calculationData"
        >
          Tu Sistema Solar
        </a>
        <a className="block menu-item" href="#technology" data-url="technology">
          Tecnología
        </a>
        <a className="block menu-item" href="#investment" data-url="investment">
          Costo de Inversión
        </a>
        {allowROI ? (
          <a
            className="block menu-item"
            href="#returnInvestment"
            data-url="returnInvestment"
          >
            Retorno de Inversión
          </a>
        ) : (
          <></>
        )}
        <a
          className="block menu-item"
          href="#medio-ambiente"
          data-url="medio-ambiente"
        >
          Medio Ambiente
        </a>
        {
          !isEpm && <a className="block menu-item" href="#firmar" data-url="firmar">
          {firmar ? "Contrato" : "Firmar"}
        </a>
        }
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => {
  const source = state.globalData.data;
  return {
    firmar: source.firmar,
    allowedROI: source["retorno de inversion"].allowROI,
    isEpm: source.cotizacion.isEpm
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);