import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";

// Utils
import { styles } from "../../../utils/handlers/stylesPDF";
import { epmLogo } from "../../../utils/handlers/handlers";

const WarrantiesPdf = () => {
  const items = [
    {
      item: "Paneles solares: Garantía de producto limitada de 12 años.",
    },
    {
      item: "Garantía de potencia limitada: Diez (10) años al 91.2% de la potencia de salida mínima y 25 años al 80.7% de la potencia de salida mínima. ",
    },
    {
      item: "Inverson de potencia: Cinco (5) años por defectos de fábrica.",
    },
    {
      item: "Instalación: Diez (10) años de garantía total.",
    },
  ];

  return (
    <>
      <View style={styles.empIconContentAbsolute}>
        <Image style={styles.epmIcon} src={epmLogo} alt="epmIcon" />
      </View>
      <View style={styles.containerWarrantiesPdf}>
        <Text style={styles.titleLeft}>Garantías</Text>
        <View style={styles.itemsContentWarranties}>
          {items.map(({ item }, i) => (
            <View key={i} style={styles.textContent}>
              <Text style={styles.point}>•</Text>
              <Text style={styles.text}>{item}</Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.containerWarranties}>
        <Text style={styles.textCenterWarranties}>
          Validez de la oferta 15 días
        </Text>
      </View>
    </>
  );
};

export default WarrantiesPdf;
