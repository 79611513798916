import { StyleSheet ,Font } from "@react-pdf/renderer";

const colorGrey = "#737373";
const colorGreyLight = "#7c7c7c";
const colorGreen = "#92BD21";
const colorGreenSecond = "#4FAC2E";
const colorYellow = "#DFDE35";

//Styled Components PDF

Font.register({
  family: 'Sora',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnn-KIwNhBti0.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnn-KIwNhBti0.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnn-KIwNhBti0.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

export const styles = StyleSheet.create({
  page: {
    color: colorGrey,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    flexDirection: "column",
    fontFamily: "Sora"
  },

  titleLeft: {
    textAlign: "left",
    fontSize: 28,
    paddingBottom: 50
  },

  // ------------  HomeView ------------
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: 560
  },
  containerEpm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100vw",
    height: "auto",
    marginTop: 70
  },
  hiName: {
    fontSize: 28,
    marginBottom: 28
  },
  textGreen: {
    color: colorGreen
  },
  textYellow: {
    color: colorYellow
  },
  containerSecond: {
    paddingRight: 30
  },
  containerThird: {
    fontSize: 23,
    marginBottom: 12
  },
  version: {
    marginBottom: 12
  },
  textP: {
    fontSize: 11
  },
  adviserContainer: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    marginTop: 20
  },
  adviserInfoContainer: {
    marginTop: 15
  },
  rightSpacing: {
    marginRight: 5
  },
  adviserCargo: {
    marginTop: 5
  },
  adviserInfo: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5
  },
  adviserView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
    fontSize: 12
  },
  adviserImage: {
    width: 72,
    height: 72,
    borderRadius: "100%"
  },
  ercoLogo: {
    position: "absolute",
    left: "35%",
    height: 38,
    bottom: 12
  },
  contentImgEmp: {
    width: 400,
    height: 400
  },
  backEpm: {
    width: "100%",
    height: "100%"
  },
  lightBulb: {
    position: "absolute",
    zIndex: -1,
    right: 0,
    bottom: 0
  },
  link: {
    marginTop: 20,
    color: colorGreen,
    opacity: 0.6,
    fontSize: 16
  },

  // ------------  ExpErco ------------
  containerExp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 100
  },
  ExpImg1: {
    width: "90%",
    height: "auto",
  },


  // ------------  National Coverage ------------
  nationalCoverageEpm: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  mapEmp: {
    width: 300,
    height: "auto"
  },
  itemsTextNational: {
    display: "flex",
    flexDirection: "row",
    fontSize: 18,
    paddingBottom: 8
  },
  nationalTextValue: {
    color: "#36A21D",
    paddingRight: 4
  },
  nationalTextTitle: {
    color: "#007934",
    paddingTop: 30,
    fontSize: 26
  },

  // ------------  EMP Items ------------
  empIconContent: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  epmIcon: {
    width: 150,
    height: "auto"
  },
  empIconContentAbsolute: {
    position: "absolute",
    top: 20,
    right: 0,
  },
  itemsContent: {
    paddingTop: 24,
    fontSize: 13
  },
  textContent: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 8,
    paddingLeft: 8
  },
  text: {
    fontSize: 11
  },
  textConditions: {
    fontSize: 12,
    textAlign: "justify"
  },
  point: {
    fontSize: 11,
    paddingRight: 4,
    color: colorGreenSecond
  },

  // ------------  Technology ------------
  containerTech: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
    // marginTop: 90
  },
  textTech: {
    width: "40%",
    fontSize: 10
  },
  imageTech: {
    width: "50%",
    marginLeft: 8,
    height: "auto"
  },
  textTitleGreen: {
    textAlign: "justify",
    width: "100%",
    color: "white",
    borderRadius: 5,
    height: "auto",
    backgroundColor: colorGreen
  },
  textTitleYellow: {
    textAlign: "justify",
    width: "100%",
    color: "white",
    borderRadius: 5,
    height: "auto",
    marginTop: 10,
    backgroundColor: colorYellow
  },
  textTitleGreyLaight: {
    textAlign: "justify",
    width: "100%",
    color: "white",
    borderRadius: 5,
    height: "auto",
    marginTop: 10,
    backgroundColor: colorGreyLight
  },
  textTitleAll: {
    fontSize: 14,
    margin: 8
  },
  textSpacingTop: {
    margin: "0 8 10 8"
  },
  textDisclaimer: {
    fontSize: 10,
    color: colorGreyLight
  },

  // ------------  Environment ------------
  containerEnv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 90,
    textAlign: "center",
    fontSize: 22
  },
  textStyle: {
    color: "white",
    fontSize: 18
  },
  containerEnvFollowed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  containerEnvFirst: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "45%",
    height: 100,
    marginTop: 20,
    borderRadius: 5,
    backgroundColor: "#A2C52B"
  },
  containerEnvFirst1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 100,
    marginTop: 20,
    borderRadius: 5,
    backgroundColor: "#A2C52B"
  },
  containerImageEnv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 70,
    height: 70,
    borderRadius: "100%",
    backgroundColor: "white"
  },
  containerImageEnv1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 95,
    height: 80,
    marginLeft: 24,
    borderRadius: "100%",
    backgroundColor: "white"
  },
  imageEnv: {
    width: "60%",
    height: "auto"
  },
  containerTextEnv: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  containerTextEnv1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // marginLeft: 180
    width: "100%",
  },
  // ---------- ComercialConditionsPdf ----------
  containerComercialConditionsPdf: {
    marginTop: 90
  },
  containerImg: {
    width: "100%",
    height: 200,
    margin: "auto",
    textAlign: "center",
    justifyContent: "center"
  },
  // --------------- Warranties -----------------
  containerWarrantiesPdf: {
    marginTop: 90,
    marginBottom: 50,
  },
  containerWarranties: {
    width: "50%",
    height: 50,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#A2C52B",
    display: "flex"
  },
  textCenterWarranties: {
    textAlign: "center",
    justifyContent: "center",
    fontSize: 23,
    fontWeight: "bold",
    color: "#ffff",
  },
  itemsContentWarranties: {
    paddingTop: 15,
    fontSize: 13
  },
  // --------------  InvestmentPdf --------------
  containerInvestment: {
    marginTop: 90
  },
  tableHeaderInv: {
    display: "flex",
    height: 25,
    fontSize: 12,
    marginBottom: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorGreenSecond,
    borderRadius: 5,
    color: "white"
  },
  itemInvestment: {
    width: "25%",
    marginTop: 3,
    textAlign: "center",
    fontSize: 12,
  },
  itemInvestmentAmount: {
    width: "20%",
    marginTop: 3,
    textAlign: "center",
    fontSize: 12,
  },
  itemInvestmentI: {
    width: "10%",
    marginTop: 3,
    textAlign: "center",
    fontSize: 12,
  },
  itemInvestmentDescription: {
    width: "45%",
    marginTop: 3,
    textAlign: "center",
    fontSize: 12,
  },
  costInvestmentDescription: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: 18,
    marginTop: 5
  },
  columnInvestmentGreen: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "45%",
    height: 100,
    borderRadius: 5,
    backgroundColor: colorGreenSecond,
    color: "white"
  },
  columnInvestmentYellow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "45%",
    height: 100,
    borderRadius: 5,
    backgroundColor: colorYellow,
    color: "white"
  },
  columnInvestmentGrey: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "45%",
    height: 100,
    borderRadius: 5,
    backgroundColor: colorGreyLight,
    color: "white"
  },
  columnInvestmentGreenLight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "45%",
    height: 100,
    borderRadius: 5,
    backgroundColor: colorGreen,
    color: "white"
  },
  icon: {
    position: "absolute",
    width: 100,
    height: "auto",
    left: 0,
    bottom: 0,
    zIndex: -1
  },
  tableRowInvEven: {
    display: "flex",
    flexDirection: "row",
    height: 20,
    backgroundColor: "rgba(229, 232, 232, 0.4)",
    borderRadius: 5,
    fontSize: 10
  },
  tableRowInv: {
    display: "flex",
    flexDirection: "row",
    height: 20,
    fontSize: 10
  },
  contentTotal: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    marginTop: 10
  },
  spaceBlank: {
    width: "56%"
  },
  ultimate: {
    display: "flex",
    flexDirection: "column",
    width: "24%"
  },
  penultimate: {
    display: "flex",
    flexDirection: "column",
    width: "20%"
  },
  itemSpaceIva: {
    marginTop: 8
  },

  // --------------  ReturnInvestment --------------
  containerReturnInvestment: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 90
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  cardContainerGreenSecond: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    height: 90,
    borderRadius: 5,
    backgroundColor: colorGreenSecond,
    color: "white"
  },
  cardContainerGreen: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    height: 90,
    borderRadius: 5,
    backgroundColor: colorGreen,
    color: "white"
  },
  cardContainerYellow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    height: 90,
    borderRadius: 5,
    backgroundColor: colorYellow,
    color: "white"
  },
  contentVar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 30
  },
  // generalText: {
  //   fontSize: 22,
  //   marginTop: 10
  // },
  variableText: {
    fontSize: 24
  },
  greenText: {
    color: colorGreen,
    marginLeft: 5
  },
  viewRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: 5,
    fontSize: 22,
    marginTop: 10
  },

  // --------- CalculationData  ---------
  containerCalculation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  calculationLeft: {
    width: "60%"
  },
  tableHeaderCalculation: {
    backgroundColor: colorGreenSecond,
    display: "flex",
    flexDirection: "row",
    height: 25,
    borderRadius: 5,
    marginTop: 3,
    fontSize: 12,
    color: "white"
  },
  itemCalculation: {
    width: "8%",
    marginTop: 3,
    marginLeft: 5,
    textAlign: "center"
  },
  itemCalculationDescription: {
    width: "70%",
    marginTop: 3,
    textAlign: "center"
  },
  itemCalculationAmount: {
    width: "22%",
    marginTop: 3,
    marginRight: 5,
    textAlign: "center"
  },
  CalculationRigth: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  calculationRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  calculationGreen: {
    color: colorGreen,
    marginLeft: 5
  },
  calculationYellow: {
    color: colorYellow,
    marginLeft: 5
  },
  cardCalculationGreenSecond: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    height: 60,
    borderRadius: 5,
    backgroundColor: colorGreenSecond,
    color: "white",
    fontSize: 10
  },
  cardCalculationGreen: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    height: 60,
    borderRadius: 5,
    backgroundColor: colorGreen,
    color: "white",
    fontSize: 10
  },
  cardCalculationYellow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    height: 60,
    borderRadius: 5,
    backgroundColor: colorYellow,
    color: "white",
    fontSize: 10
  },
  cardCalculationGrey: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    height: 60,
    borderRadius: 5,
    backgroundColor: colorGreyLight,
    color: "white",
    fontSize: 10
  },
  calculationValue: {
    fontSize: 14
  },
  iconCalculation: {
    position: "absolute",
    width: 60,
    height: "auto",
    left: 0,
    bottom: 0,
    zIndex: -1
  },
  solarEnergy: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "40%",
    height: 60
  },
  units: {
    fontSize: 10
  },
  calculationSolar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "40%",
    height: 60,
    borderRadius: 5,
    backgroundColor: colorGreen,
    fontSize: 10,
    color: "white"
  },
  solarText: {
    fontSize: 14
  }
});
