import React, { useEffect, useState } from 'react';

export default function Sliders({id, min, max, defaultValue, action, actionLoading, label, units, step}) {

    const [temValue, setTemValue] = useState(defaultValue)

    useEffect(() => {
        changeSlideStyle(id)
    });

    const changeSlideStyle = (id) => {

        const filled = ((temValue - min)/(max - min)) * 100;

        document.querySelector(`#${id}`).style.background = `
            linear-gradient(to right, #4FAC2E ${filled}%, #A7D596 ${filled}%)
        `;
    }

    const handleOnChange = (e) => {
        const element = e.target;
        changeSlideStyle(element.id);
        setTemValue(element.value);
        if(actionLoading) {
            actionLoading(true);
        }
    }

    const handleMouseUp = (e) => {
        const element = e.target;
        action(element.value);
        if(actionLoading) {
            actionLoading(false);
        }
    }

    return (
        <div className="sliderContainer w-full mb-5 pr-2">
            <div className="flex justify-between">
                <h6>{label}</h6>
                <h6>{temValue} {units}</h6>
            </div>
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={temValue}
                className="slider"
                id={id}
                onChange={(e) => handleOnChange(e)}
                onMouseUp={(e) => handleMouseUp(e)}
                onTouchEnd={(e) => handleMouseUp(e)}
            />
            <div className="w-full flex justify-between">
                <p>{min}</p>
                <p>{max}</p>
            </div>
        </div>
    )
}
