import React from 'react';

import { ResponsiveLine } from '@nivo/line'
import EnergyCostChartTooltip from './EnergyCostChartTooltip';

export default function EnergyCostChart({ energyData }) {

    const screenWidth = window.innerWidth;
    let options = {};

    if(screenWidth < 1024) {
        options.anchor = 'bottom'
        options.direction = 'row'
        options.translateX = 0
        options.translateY = 90
        options.top = 10
        options.right = 10
        options.bottom = 120
        options.left = 60
    } else {
        options.anchor = 'bottom-right'
        options.direction = 'column'
        options.translateX = 160
        options.translateY = -10
        options.top = 10
        options.right = 150
        options.bottom = 160
        options.left = 90
    }

    const legends = [
        {
            anchor: options.anchor,
            direction: options.direction,
            translateX: options.translateX,
            translateY: options.translateY,
            itemsSpacing: 10,
            itemDirection: 'left-to-right',
            itemWidth: 140,
            itemHeight: 20,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)'
        }
    ];

    const axisConfig = {
        bottom: {
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickValues: 5,
            legend: 'Año',
            legendOffset: 42,
            legendPosition: 'middle'
        },
        left: {
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            legend: 'Valor',
            legendOffset: -48,
            legendPosition: 'middle'
        }
    }
    
    return (
        <div className="chart-container">
            <ResponsiveLine
                data={energyData}
                margin={{ top: options.top, right: options.right, bottom: options.bottom, left: options.left }}
                xScale={{ type: 'linear' }}
                yScale={{ type: 'linear', min: '0', max: 'auto' }}
                colors={{ datum: 'color' }}
                enableGridY={false}
                yFormat=" >-.2f"
                enableSlices="x"
                axisBottom={axisConfig.bottom}
                axisLeft={axisConfig.left}
                enablePoints={false}
                pointColor={{ from: 'color' }}
                legends={legends}
                lineWidth={4}
                sliceTooltip={({slice}) => (
                    <EnergyCostChartTooltip sliceData={slice} />
                )}
                animate={true}
                motionDamping={10}
                motionStiffness={80}
            />
        </div>
    )
}
